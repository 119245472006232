import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { ButtonRow, FormStepper, Loader } from '@omnigenbiodata/ui';
import { Navigate } from 'react-router-dom';
import InnerLayout from '../../../../../../../../layout/Inner';
import FORMS from '../../../../../../../../core/constants/forms.constants';
import {
  DEFAULT_ALIQUOT_STATUS,
  DEFAULT_ALIQUOT_STATUS_NOT_COLLECTED,
  DEFAULT_ALIQUOT_VOLUME,
  DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME,
} from '../../../../../../../../core/constants/lab.constants';
import { Panel } from '../../../../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import {
  samplesSelector,
  sampleTypeSelector,
  hasErrorSelector,
  isBusySelector,
  responseSelector,
} from '../../../../../../../../store/batchCreate/selectors';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { AliquotsStatusList, AliquotsStatusRow } from './components';
import { createSampleBatchThunk } from '../../../../../../../../store/batchCreate';
import { CreateSampleBatchInput, SampleTypeCodeEnum } from '../../../../../../../../core/api/lab.types';
import { SampleFormValues } from '../../../../../../../../store/batchCreate/types';
import { getAliquotsStatusSchema } from '../../../../../../../../core/validation/aliquots.validation';

function AliquotsStatusScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      sampleType,
      samples: samples
        ? samples.map((sample) => {
            return {
              ...sample,
              aliquot1Vol: `${
                sample.volume !== '0'
                  ? sampleType === SampleTypeCodeEnum.WHOLE
                    ? DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME
                    : DEFAULT_ALIQUOT_VOLUME
                  : '0'
              }`,
              aliquot2Vol: `${
                sample.volume !== '0'
                  ? sampleType === SampleTypeCodeEnum.WHOLE
                    ? DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME
                    : DEFAULT_ALIQUOT_VOLUME
                  : '0'
              }`,
              aliquot1Condition: sample.volume !== '0' ? DEFAULT_ALIQUOT_STATUS : DEFAULT_ALIQUOT_STATUS_NOT_COLLECTED,
              aliquot2Condition: sample.volume !== '0' ? DEFAULT_ALIQUOT_STATUS : DEFAULT_ALIQUOT_STATUS_NOT_COLLECTED,
            };
          })
        : null,
    },
    validationSchema: getAliquotsStatusSchema(),
    onSubmit: (values) => {
      dispatch(
        createSampleBatchThunk({
          sampleType: values.sampleType,
          samples: values.samples
            ? values.samples.map((sample) => {
                return {
                  barcode: sample.barcode,
                  status: sample.condition,
                  volume: sample.volume,
                  aliquots: [
                    {
                      barcode: sample.aliquot1IDConfirm,
                      rgcBarcode: sample.rgc1ID,
                      mismatch: sample.aliquot1IDMismatch,
                      status: sample.aliquot1Condition,
                      volume: sample.aliquot1Vol,
                    },
                    {
                      barcode: sample.aliquot2IDConfirm,
                      rgcBarcode: sample.rgc2ID,
                      mismatch: sample.aliquot2IDMismatch,
                      status: sample.aliquot2Condition,
                      volume: sample.aliquot2Vol,
                    },
                  ],
                };
              })
            : [],
        } as CreateSampleBatchInput),
      );
    },
  });

  if (response) {
    return <Navigate to={ROUTES.batchesCreateComplete} />;
  }

  if (!sampleType || !samples) {
    return <Navigate to={ROUTES.batchesCreate} />;
  }

  return (
    <>
      <InnerLayout>
        <FormikProvider value={formik}>
          <Form>
            <FieldArray name="samples">
              {() => (
                <>
                  <Typography variant="h4" component="h1" align="center" paragraph>
                    Sample Processing
                  </Typography>
                  <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={4} />
                  <Panel
                    mb={6}
                    title="Aliquot Status"
                    intro="Please record the status and volume of each aliquot sample"
                  >
                    {hasError && !isBusy && (
                      <Box mb={2}>
                        <Alert severity="error">
                          There was an unexpected problem submitting the samples and aliquots below.
                        </Alert>
                      </Box>
                    )}
                    {formik.values?.samples && formik.values?.samples?.length > 0 && (
                      <AliquotsStatusList>
                        {formik.values.samples.map((sample: SampleFormValues, index: number) => (
                          <AliquotsStatusRow key={`${sample.barcode}-${index}`} index={index} sampleType={sampleType} />
                        ))}
                      </AliquotsStatusList>
                    )}
                  </Panel>
                  <ButtonRow
                    showForward={formik.isValid}
                    forwardLabel="Submit"
                    buttonSize="large"
                    forwardColor="primary"
                  />
                </>
              )}
            </FieldArray>
          </Form>
        </FormikProvider>
      </InnerLayout>
      <Loader isVisible={isBusy} label={'Submitting'} />
    </>
  );
}

export default AliquotsStatusScene;
