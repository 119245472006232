import { createAsyncThunk } from '@reduxjs/toolkit';
import labApi from '../../core/api/lab.api';
import { FindCryoboxesResponse, RequestSampleResponse, SampleExportStatusResponse } from '../../core/api/lab.types';
import { CryoboxListFormValues } from './types';

/* Find Sample Batches */
export const findCryoboxesThunk = createAsyncThunk(
  'cryoboxList/findCryoboxes',
  async (values: CryoboxListFormValues, { rejectWithValue }: any) => {
    const response: FindCryoboxesResponse = await labApi.findCryoboxes(
      values.searchTerm || '',
      values.freezerId || '',
      values.bioBanked || '',
      values.offset,
      values.limit,
    );
    if (response.data?.findCryoboxes) {
      return response.data?.findCryoboxes;
    }
    return rejectWithValue(response.errors);
  },
);

export const getSampleExportThunk = createAsyncThunk('cryoboxList/getExport', async (values, thunkApi) => {
  // initiate export creation
  const requestResponse: RequestSampleResponse = await labApi.requestSampleExport();
  if (requestResponse.errors) {
    return thunkApi.rejectWithValue(requestResponse.errors);
  }
  // poll for created export
  const exportResponse: SampleExportStatusResponse = await pollExportStatus();
  if (exportResponse?.errors) {
    return thunkApi.rejectWithValue(exportResponse.errors);
  }
  return exportResponse.data?.getSampleExportStatus as SampleExportStatusResponse;
});

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const pollExportStatus = async (): Promise<any> => {
  await wait(1000);
  return labApi.getSampleExportStatus().then((response) => {
    if (
      response.data?.getSampleExportStatus.exportLink ||
      response.errors ||
      response.data?.getSampleExportStatus.jobError
    ) {
      return response as SampleExportStatusResponse;
    }
    return pollExportStatus();
  });
};
