/* Validation Rules */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const MESSAGES = {
  cryoboxBarcodeRequired: 'Cryobox barcode is required',
  emailInvalid: 'Invalid E-mail address',
  emailRequired: 'E-mail is required',
  firstNameRequired: 'First Name is required',
  lastNameRequired: 'Surname is required',
  otpRequired: 'One-time password is required',
  passwordConfirmRequired: 'Confirm new password is required',
  passwordRequired: 'Password is required',
  passwordFormat:
    'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
  passwordNoMatch: "Passwords don't match",
  sampleTypeRequired: 'Sample type required',
  primarySamplesEmpty: 'Samples cannot be empty',
  primarySamplesFull: 'Max sample rows 10',
  primarySampleRequired: 'Primary barcode required',
  primarySamplesAlreadyScanned: 'Primary barcode already scanned',
  primarySamplesInvalid: 'Invalid barcode',
  primarySamplesVolume: 'Volume Required',
  primarySamplesVolumePositive: 'Must be equal or greater than 0',
  primarySamplesCondition: 'Condition Required',
  primarySamplesDuplicate: 'Barcode already scanned',
  aliquotRequired: 'Aliquot Barcode Required',
  aliquot1VolRequired: 'Required',
  aliquotInvalid: 'Invalid Aliquot Barcode',
  aliquotPostfixInvalid: 'Invalid Aliquot Postfix',
  aliquotMismatch: 'Primary Barcode mismatch',
  serialNoRequired: 'Serial number required',
  cryoBoxWidthRequired: 'Columns required',
  cryoBoxHeightRequired: 'Rows required',
  storageAliquotsRequired: 'Scan at least one cryovial',
  freezerIdRequired: 'Freezer Name required',
  biobankedRequired: 'Biobank status required',
  freezerShelfNoRequired: 'Shelf No Required',
  shelfRackNoRequired: 'Rack No Required',
  rackPositionNoRequired: 'Position No Required',
  aliquotPostfixMismatch: 'Aliquot postfixes do not match',
  sampleSearchTermRequired: 'Required',
  rgcRequired: 'RGC Barcode Required',
  rgcInvalid: 'RGC Barcode Invalid',
};

export const PROCESSING_STEPS = ['Sample Type', 'Primary Tubes', 'Cryo Vials', 'Aliquot Position', 'Aliquot Status'];
export const STORAGE_STEPS = ['Sample Type', 'Cryobox', 'Positions', 'Freezer', 'Complete'];

const FORMS = {
  PASSWORD_REGEX,
  MESSAGES,
  PROCESSING_STEPS,
  STORAGE_STEPS,
};
export default FORMS;
