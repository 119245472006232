import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import InnerLayout from '../../../../layout/Inner';
import LinkingForm from './compionents/LinkingForm';
import { AlertError, ScanEvent } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  firstErrorSelector,
  hasErrorSelector,
  isBusySelector,
  linkRGCBarcodeThunk,
  responseSelector,
} from '../../../../store/rgcLinking';
import { LinkRGCBarcodeInput } from '../../../../core/api/lab.types';
import { Loader } from '@omnigenbiodata/ui';
import AlertSuccess from '../../../../components/content/AlertSuccess';
import { LinkingErrorTypes } from '../../../../store/rgcLinking/types';

function LinkScene() {
  const [scannedBarcodes, setScannedBarcodes] = useState<string[] | null>(null);
  const [previousBarcodes, setPreviousBarcodes] = useState<string[] | null>(null);
  const dispatch = useAppDispatch();
  const linkingResponse = useAppSelector(responseSelector);
  const hasLinkingError = useAppSelector(hasErrorSelector);
  const firstError = useAppSelector(firstErrorSelector);
  const linkingIsBusy = useAppSelector(isBusySelector);

  const handleLink = (values: LinkRGCBarcodeInput) => {
    setPreviousBarcodes(Object.values(values));
    setScannedBarcodes(null);
    dispatch(linkRGCBarcodeThunk(values));
  };

  const handleDeleteScan = (index: number) => {
    if (scannedBarcodes !== null) {
      if (index === 0) {
        setScannedBarcodes(null);
      } else {
        setScannedBarcodes([scannedBarcodes[0]]);
      }
    }
  };

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Link RGC Barcodes
      </Typography>
      <ScanEvent
        data-testid="scanner"
        onScan={(scanCode) => {
          if (scannedBarcodes === null || scannedBarcodes.length <= 2) {
            setScannedBarcodes(scannedBarcodes !== null ? [...scannedBarcodes, scanCode] : [scanCode]);
          }
        }}
      />

      {hasLinkingError && !linkingIsBusy && previousBarcodes && firstError === LinkingErrorTypes.ALIQUOT_NOT_FOUND && (
        <AlertError title="Linking Failed" description={`Aliquot (${previousBarcodes[0]}) does not exist`} />
      )}
      {hasLinkingError && !linkingIsBusy && previousBarcodes && firstError !== LinkingErrorTypes.ALIQUOT_NOT_FOUND && (
        <AlertError
          title="Linking Failed"
          description={`Aliquot (${previousBarcodes[0]}) could not be linked to (${previousBarcodes[1]}. Please try again))`}
        />
      )}
      {linkingResponse && !linkingIsBusy && previousBarcodes && (
        <AlertSuccess
          title="Success"
          description={`Aliquot (${previousBarcodes[0]}) successfully linked to RGC Barcode (${previousBarcodes[1]})`}
        />
      )}
      <LinkingForm
        aliquotId={scannedBarcodes && scannedBarcodes.length > 0 ? scannedBarcodes[0] : ''}
        rgcBarcode={scannedBarcodes && scannedBarcodes.length > 1 ? scannedBarcodes[1] : ''}
        onDelete={handleDeleteScan}
        onLink={handleLink}
      />
      <Loader isVisible={linkingIsBusy} />
    </InnerLayout>
  );
}

export default LinkScene;
