import React from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Formatter } from '@omnigenbiodata/ui';
import { Grid } from '@mui/material';
import { SampleTypeCodeEnum } from '../../../../../../../../core/api/lab.types';
import { Panel } from '../../../../../../../../components';

export interface BatchSummaryProps {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  createdTs: string;
}

function BatchSummary({ batchId, sampleType, createdTs }: BatchSummaryProps) {
  return (
    <Panel mb={4}>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Chip label={sampleType} color="primary" variant="outlined" />
        </Grid>
        <Grid item xs>
          <Typography component="h2" variant="h5">
            {batchId}
          </Typography>
          <Typography component="h2" variant="body1">
            <Formatter input={createdTs} format="friendlyDateTime" />
          </Typography>
        </Grid>
      </Grid>
    </Panel>
  );
}

export default BatchSummary;
