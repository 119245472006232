import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Navigate, useNavigate } from 'react-router-dom';
import { ButtonRow, FormInput, FormStepper } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../../../../../layout/Inner';
import Panel from '../../../../../../../../components/layout/Panel';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { cryoboxCreateForward } from '../../../../../../../../store/cryoboxCreate';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import FORMS, { MESSAGES } from '../../../../../../../../core/constants/forms.constants';
import { valuesSelector, sampleTypeSelector } from '../../../../../../../../store/cryoboxCreate/selectors';
import { SampleTypeCodeEnum } from '../../../../../../../../core/api/lab.types';
import { RiBarcodeLine } from 'react-icons/ri';

function CryoBoxScene() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(valuesSelector);
  const sampleType = useAppSelector(sampleTypeSelector);
  const navigateTo = useNavigate();

  const validationSchema = yup.object({
    serialNo: yup.string().required(MESSAGES.serialNoRequired),
    cryoboxBarcode: yup.string().when('sampleType', {
      is: (sampleType) => sampleType === SampleTypeCodeEnum.WHOLE,
      then: yup.string().required(MESSAGES.cryoboxBarcodeRequired),
    }),
    width: yup.number().required(MESSAGES.cryoBoxWidthRequired).min(1, 'Must be greater than 0'),
    height: yup.number().required(MESSAGES.cryoBoxHeightRequired).min(1, 'Must be greater than 0'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serialNo: '',
      cryoboxBarcode: '',
      width: '',
      height: '',
      ...values,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        cryoboxCreateForward({
          ...values,
          serialNo: values.serialNo.trim(),
        }),
      );
      navigateTo(ROUTES.cryoboxCreateAliquots);
    },
  });

  if (!sampleType || !values) {
    return <Navigate to={ROUTES.cryoboxCreate} />;
  }

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Typography variant="h4" component="h1" align="center" paragraph mb={5}>
            Sample Storage
          </Typography>
          <FormStepper steps={FORMS.STORAGE_STEPS} activeStep={1} />
        </Box>
        <Panel mb={6} mt={5} title="Cryobox" intro="Please enter the cryobox details below:">
          <FormInput
            label="Cryobox Number"
            type="text"
            name={`serialNo`}
            error={formik.errors.serialNo}
            touched={formik.touched.serialNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.serialNo}
          />
          <FormInput
            label={sampleType === SampleTypeCodeEnum.WHOLE ? 'Cryobox Barcode' : 'Cryobox Barcode (Optional)'}
            type="text"
            name={`cryoboxBarcode`}
            error={formik.errors.cryoboxBarcode}
            touched={formik.touched.cryoboxBarcode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cryoboxBarcode}
            startAdornment={<RiBarcodeLine />}
          />
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <FormInput
                label="Rows"
                type="number"
                name={`height`}
                step={1}
                min={0}
                error={formik.errors.height}
                touched={formik.touched.height}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.height}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInput
                label="Columns"
                type="number"
                name={`width`}
                step={1}
                min={0}
                error={formik.errors.width}
                touched={formik.touched.width}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.width}
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            {parseInt(formik.values.width) > 0 && parseInt(formik.values.height) > 0 && (
              <>
                <Typography variant="h6" component="p" align="center">
                  Cryobox maximum capacity:
                </Typography>
                <Typography variant="h1" component="p" align="center">
                  {parseInt(formik.values.width) * parseInt(formik.values.height)}{' '}
                </Typography>
              </>
            )}
          </Box>
        </Panel>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default CryoBoxScene;
