import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { Freezer } from '../../core/api/lab.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.freezerList.errors && state.freezerList.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.freezerList.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Freezer[] | null => state.freezerList.response || null;
