import React from 'react';
import { useFormikContext } from 'formik';
import { FormInput, theme } from '@omnigenbiodata/ui';
import { RiBarcodeLine, RiCheckLine } from 'react-icons/ri';
import { useStyles } from './component.styles';
import clsx from 'classnames';
import { SampleFormValues } from '../../../../../../../../../../store/batchCreate/types';
import Typography from '@mui/material/Typography';
import { SampleTypeCodeEnum } from '../../../../../../../../../../core/api/lab.types';

interface AliquotConfirmRowProps {
  index: number;
  sampleType: SampleTypeCodeEnum;
}

const AliquotConfirmRow = ({ index, sampleType }: AliquotConfirmRowProps) => {
  const formik = useFormikContext<{
    samples: SampleFormValues[];
  }>();
  const { classes } = useStyles();

  return (
    <tr>
      <td
        align="center"
        className={clsx({
          [classes.leftCellRed]: sampleType === SampleTypeCodeEnum.SERUM,
          [classes.leftCellPurple]: sampleType !== SampleTypeCodeEnum.SERUM,
        })}
      >
        {index + 1}
      </td>
      <td align="center">
        <Typography variant="body1" component="p" align="center">
          <RiBarcodeLine
            fontSize={25}
            color={theme.palette.grey[500]}
            style={{ verticalAlign: 'middle', marginRight: 5 }}
          />
          {formik.values.samples[index].barcode}
        </Typography>
      </td>
      <td>
        <FormInput
          label="Aliquot 1 ID"
          type="text"
          name={
            formik.errors?.samples && (formik.errors?.samples[index] as SampleFormValues)
              ? `samples.${index}.aliquot1ID.error`
              : `samples.${index}.aliquot1ID`
          }
          error={
            formik.errors?.samples && (formik.errors?.samples[index] as SampleFormValues)
              ? (formik.errors?.samples[index] as SampleFormValues).aliquot1ID
              : undefined
          }
          touched={
            formik.touched?.samples && formik.touched?.samples[index] ? formik.touched.samples[index].aliquot1ID : false
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.samples[index].aliquot1ID || ''}
          showErrorMessage={false}
          disabled
          startAdornment={<RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />}
          endAdornment={<RiCheckLine fontSize={25} color={theme.palette.primary.main} />}
        />
      </td>
      <td>
        <FormInput
          label="Aliquot 2 ID"
          type="text"
          name={
            formik.errors?.samples && (formik.errors?.samples[index] as SampleFormValues)
              ? `samples.${index}.aliquot2ID.error`
              : `samples.${index}.aliquot2ID`
          }
          error={
            formik.errors?.samples && (formik.errors?.samples[index] as SampleFormValues)
              ? (formik.errors?.samples[index] as SampleFormValues).aliquot2ID
              : undefined
          }
          touched={
            formik.touched?.samples && formik.touched?.samples[index] ? formik.touched.samples[index].aliquot2ID : false
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.samples[index].aliquot2ID || ''}
          showErrorMessage={false}
          disabled
          startAdornment={<RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />}
          endAdornment={<RiCheckLine fontSize={25} color={theme.palette.primary.main} />}
        />
      </td>
    </tr>
  );
};

export default AliquotConfirmRow;
