import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Fade from '@mui/material/Fade';
import { PageWrapper, DMSALogo } from '@omnigenbiodata/ui';

import { useStyles } from './component.styles';
import ANIMATIONS from '../../core/constants/animations.constants';

interface InnerLayoutProps {
  children: boolean | ReactNode;
}

function InnerLayout({ children }: InnerLayoutProps) {
  const { classes } = useStyles();

  return (
    <>
      <PageWrapper>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <Link to="/" className={classes.logo}>
              <DMSALogo size="xs" />
            </Link>
          </div>
        </div>
        <Fade in timeout={ANIMATIONS.duration}>
          <div className={classes.content}>{children}</div>
        </Fade>
      </PageWrapper>
    </>
  );
}

export default InnerLayout;
