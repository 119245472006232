import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { RiPhoneLockLine } from 'react-icons/ri';
import QRCode from 'react-qr-code';
import { ButtonRow, FormInput, Loader } from '@omnigenbiodata/ui';
import { useAuth, AuthLayout } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import { MESSAGES } from '../../../../core/constants/forms.constants';
import { AUTH_CHALLENGES, MFA_ISSUER } from '../../../../core/constants/aws.constants';

const validationSchema = yup.object().shape({
  otp: yup.string().required(MESSAGES.otpRequired),
});

function MultiFactor() {
  const { hasError, isBusy, challengeName, user, setupCode, completeSetupMfa, completeMfa } = useAuth();
  const { t } = useTranslation('portal');
  const formik = useFormik({
    initialValues: { otp: '' },
    validationSchema,
    onSubmit: (values: any) => {
      const { otp } = values;
      if (challengeName === AUTH_CHALLENGES.MFA_SETUP) {
        completeSetupMfa(otp);
      } else {
        completeMfa(otp);
      }
    },
  });

  return (
    <AuthLayout
      title={t('mfa.title')}
      text={t('mfa.text')}
      hasError={hasError}
      dmsa
      errorTitle={t('alerts.mfaFailed.title')}
      errorText={t('alerts.mfaFailed.text')}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box mb={8}>
          <FormInput
            error={formik.errors.otp}
            name="otp"
            label={t('forms.otp')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.otp}
            value={formik.values.otp}
            startAdornment={<RiPhoneLockLine />}
          />
        </Box>
        {setupCode && user && (
          <>
            <div style={{ textAlign: 'center', marginBottom: 90 }}>
              <QRCode
                value={
                  `otpauth://totp/${MFA_ISSUER}:` + user.username + '?secret=' + setupCode + `&issuer=${MFA_ISSUER}`
                }
              />
            </div>
            <span id="setupCode" style={{ opacity: 0 }}>
              {setupCode}
            </span>
          </>
        )}
        <ButtonRow forwardLabel={t('buttons.confirm')} buttonSize="large" forwardColor="primary" />
      </form>
      <Loader isVisible={isBusy} label={t('loaders.check') as string} />
    </AuthLayout>
  );
}

export default MultiFactor;
