import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormStepper } from '@omnigenbiodata/ui';
import { Navigate, useNavigate } from 'react-router-dom';
import InnerLayout from '../../../../../../../../layout/Inner';
import FORMS from '../../../../../../../../core/constants/forms.constants';
import { Panel, ScanEvent } from '../../../../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { samplesSelector, sampleTypeSelector } from '../../../../../../../../store/batchCreate/selectors';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { CryoVialList, CryoVialRow } from './components';
import { batchCreateForward } from '../../../../../../../../store/batchCreate';
import { SampleFormValues } from '../../../../../../../../store/batchCreate/types';
import { getAliquotsSchemaInitial } from '../../../../../../../../core/validation/aliquots.validation';
import { SampleTypeCodeEnum } from '../../../../../../../../core/api/lab.types';

function CryoVialsScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const [scanned, setScanned] = useState<string[]>([]);
  const [scannedRows, setScannedRows] = useState<string[][]>([]);
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      samples:
        (samples?.map((sample, index) => {
          return {
            ...sample,
            aliquot1ID: scannedRows[index] ? scannedRows[index][0] : undefined,
            rgc1ID: scannedRows[index] && sampleType === SampleTypeCodeEnum.WHOLE ? scannedRows[index][1] : undefined,
            aliquot2ID: scannedRows[index]
              ? sampleType === SampleTypeCodeEnum.WHOLE
                ? scannedRows[index][2]
                : scannedRows[index][1]
              : undefined,
            rgc2ID: scannedRows[index] && sampleType === SampleTypeCodeEnum.WHOLE ? scannedRows[index][3] : undefined,
          };
        }) as SampleFormValues[]) || [],
    },
    initialTouched: {
      samples:
        samples?.map((sample, index) => {
          return {
            aliquot1ID: scannedRows[index] && scannedRows[index][0] ? true : false,
            rgc1ID: scannedRows[index] && scannedRows[index][1] ? true : false,
            aliquot2ID:
              scannedRows[index] && sampleType === SampleTypeCodeEnum.WHOLE
                ? scannedRows[index] && scannedRows[index][2]
                  ? true
                  : false
                : scannedRows[index] && scannedRows[index][1]
                  ? true
                  : false,
            rgc2ID: scannedRows[index] && scannedRows[index][3] ? true : false,
          };
        }) || [],
    },
    validationSchema: getAliquotsSchemaInitial(sampleType),
    onSubmit: (values) => {
      dispatch(batchCreateForward(values));
      navigateTo(ROUTES.batchesCreateAliquotting);
    },
  });

  const scanningPaused = useMemo(() => {
    return (
      formik.values.samples
        .filter((sample, index) => {
          const errors = formik.errors.samples ? (formik.errors.samples[index] as any) : {};
          const touched = formik.touched.samples ? (formik.touched.samples[index] as any) : {};

          return (
            (sample.aliquot1ID && errors?.aliquot1ID && touched?.aliquot1ID) ||
            (sample.aliquot2ID && errors?.aliquot2ID && touched?.aliquot2ID) ||
            (sample.rgc1ID && errors?.rgc1ID && touched?.rgc1ID) ||
            (sample.rgc2ID && errors?.rgc2ID && touched?.rgc2ID)
          );
        }, [])
        .map((sample) => sample.barcode).length > 0
    );
  }, [formik.values, formik.errors, formik.touched]);

  const handleRemoveCryoRow = (rowIndex: number) => {
    const newScanned = scannedRows.reduce((prev, curr, index) => {
      const newRow = [...curr];
      if (rowIndex === index) {
        newRow.pop();

        return [...prev, ...newRow];
      }
      return [...prev, ...curr];
    }, []);
    setScanned(newScanned);
    setScannedRows(_.chunk(newScanned, sampleType === SampleTypeCodeEnum.WHOLE ? 4 : 2));
  };

  const handleScan = (scanCode: string) => {
    if (!scanningPaused) {
      const newScanned = [...scanned];
      if (scanned.filter((filterValue) => filterValue === scanCode).length === 0) {
        newScanned.push(scanCode);
      }

      setScanned(newScanned);
      setScannedRows(_.chunk(newScanned, sampleType === SampleTypeCodeEnum.WHOLE ? 4 : 2));
    }
  };

  if (!sampleType || !samples) {
    return <Navigate to={ROUTES.batchesCreate} />;
  }

  return (
    <InnerLayout>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray name="samples">
            {() => (
              <>
                <ScanEvent data-testid="scanner" onScan={handleScan} />
                <Typography variant="h4" component="h1" align="center" paragraph mb={5}>
                  Sample Processing
                </Typography>
                <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={2} />
                <Panel
                  mb={6}
                  mt={5}
                  title="Aliquot Initial Positions"
                  intro="Please scan the labels on the EMPTY cryovials linked to each primary barcode"
                >
                  {formik.values.samples.length > 0 && (
                    <CryoVialList>
                      {formik.values.samples.map((sample: SampleFormValues, index: number) => (
                        <CryoVialRow
                          key={`${sample.barcode}-${index}`}
                          index={index}
                          sampleType={sampleType}
                          onDelete={handleRemoveCryoRow}
                        />
                      ))}
                    </CryoVialList>
                  )}
                </Panel>
                <ButtonRow showForward={formik.isValid} buttonSize="large" forwardColor="primary" />
              </>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </InnerLayout>
  );
}

export default CryoVialsScene;
