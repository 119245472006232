import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { SampleBatch } from '../../core/api/lab.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.batch.errors && state.batch.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.batch.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): SampleBatch | null => state.batch.response || null;
