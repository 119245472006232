import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  header: {
    flexGrow: 0,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
  },
  footer: { flexGrow: 0 },
  MainName: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: { marginBottom: theme.spacing(10) },
  },
  logo: {
    display: 'block',
    textAlign: 'center',
  },
}));
