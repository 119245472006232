export const ROOT = `/`;
export const AUTH_BASE = `${ROOT}auth/`;
export const PORTAL_ROOT = `${ROOT}portal/`;
export const BATCHES_ROOT = `${PORTAL_ROOT}batches/`;
export const BATCHES_CREATE_ROOT = `${BATCHES_ROOT}create/`;
export const BATCHES_EDIT_ROOT = `${BATCHES_ROOT}edit/`;
export const STORAGE_ROOT = `${PORTAL_ROOT}storage/`;
export const STORAGE_CREATE_ROOT = `${STORAGE_ROOT}create/`;
export const STORAGE_EDIT_ROOT = `${STORAGE_ROOT}edit/`;
export const LINKING_ROOT = `${PORTAL_ROOT}linking/`;

export const ROUTES = {
  root: ROOT,
  auth: AUTH_BASE,
  authMultiFactor: `${AUTH_BASE}mfa`,
  authNewPassword: `${AUTH_BASE}newpassword`,
  authResetPassword: `${AUTH_BASE}resetpassword`,
  authSubmitResetPassword: `${AUTH_BASE}submitresetpassword`,
  authSignOut: `${AUTH_BASE}logout`,
  portal: PORTAL_ROOT,
  batches: `${BATCHES_ROOT}`,
  batchesCreate: `${BATCHES_CREATE_ROOT}`,
  batchesCreatePrimaryTubes: `${BATCHES_CREATE_ROOT}primaryTubes`,
  batchesCreateCryoVials: `${BATCHES_CREATE_ROOT}cryoVials`,
  batchesCreateAliquotting: `${BATCHES_CREATE_ROOT}aliquotting`,
  batchesCreateAliquots2: `${BATCHES_CREATE_ROOT}aliquots2`,
  batchesCreateAliquotsStatus: `${BATCHES_CREATE_ROOT}status`,
  batchesCreateComplete: `${BATCHES_CREATE_ROOT}complete`,
  batchesEdit: `${BATCHES_EDIT_ROOT}:batchid`,
  cryobox: `${STORAGE_ROOT}`,
  cryoboxCreate: `${STORAGE_CREATE_ROOT}`,
  cryoboxCreateBox: `${STORAGE_CREATE_ROOT}box`,
  cryoboxCreateAliquots: `${STORAGE_CREATE_ROOT}aliquots`,
  cryoboxCreateFreezer: `${STORAGE_CREATE_ROOT}freezer`,
  cryoboxCreateComplete: `${STORAGE_CREATE_ROOT}complete`,
  cryoboxEdit: `${STORAGE_EDIT_ROOT}:cryoboxUuid`,
  linking: `${LINKING_ROOT}`,
};

export default ROUTES;
