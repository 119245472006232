import React from 'react';
import { RiArrowLeftLine, RiPauseLine } from 'react-icons/ri';
import { FaUndo } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import { theme } from '@omnigenbiodata/ui';

interface EndAdornmentProps {
  allowDelete: boolean;
  isActive?: string | boolean;
  index: number;
  onDelete: (index: number) => void;
}

const EndAdornment = ({
  allowDelete = false,
  isActive = false,
  index = 0,
  onDelete = () => undefined,
}: EndAdornmentProps) => {
  return (
    <>
      {isActive ? (
        <RiArrowLeftLine fontSize={25} color={theme.palette.primary.main} />
      ) : allowDelete ? (
        <>
          <IconButton
            aria-label="Re-scan"
            size="large"
            onClick={() => {
              onDelete(index);
            }}
            title="Re-scan"
          >
            <FaUndo fontSize={20} color={theme.palette.error.dark} />
          </IconButton>
        </>
      ) : (
        <RiPauseLine fontSize={25} color={theme.palette.grey[500]} />
      )}
    </>
  );
};

export default EndAdornment;
