import React from 'react';
import { IoMdLink, IoMdGrid, IoIosListBox } from 'react-icons/io';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ROUTES from '../../../../../../core/constants/routes.constants';
import { Link } from 'react-router-dom';

function HomeOptions() {
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.batchesCreate}
            startIcon={<IoMdGrid size={40} />}
          >
            Sample Processing
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.cryoboxCreate}
            startIcon={<IoIosListBox size={40} />}
          >
            Sample Storage
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.linking}
            startIcon={<IoMdLink size={40} />}
          >
            RGC Linking
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeOptions;
