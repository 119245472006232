import React from 'react';
import { RiLock2Line, RiArrowLeftLine, RiPauseLine, RiLockLine } from 'react-icons/ri';
import { FaUndo } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import { theme } from '@omnigenbiodata/ui';

interface EndAdornmentProps {
  errors?: any;
  touched?: boolean;
  value?: string;
  isActive?: string | boolean;
  index: number;
  columnIndex: number;
  onDelete?: (index: number, col: number) => void;
  onLock?: (index: number, col: number) => void;
}

const EndAdornment = ({
  errors,
  touched = false,
  value = '',
  isActive = false,
  index,
  columnIndex,
  onDelete = () => undefined,
  onLock = () => undefined,
}: EndAdornmentProps) => {
  return (
    <>
      {isActive ? (
        <RiArrowLeftLine fontSize={25} color={theme.palette.primary.main} />
      ) : errors && touched ? (
        <>
          <IconButton
            aria-label="Re-scan accidental mismatch"
            data-testid="remove-aliquot"
            size="large"
            onClick={() => {
              onDelete(index, columnIndex);
            }}
            title="Re-scan accidental mismatch"
          >
            <FaUndo fontSize={20} color={theme.palette.error.dark} />
          </IconButton>
          <IconButton
            aria-label="Lock genuine mismatch"
            data-testid="lock-aliquot"
            size="large"
            onClick={() => {
              onLock(index, columnIndex);
            }}
            title="Lock genuine mismatch"
          >
            <RiLockLine fontSize={25} color={theme.palette.success.dark} />
          </IconButton>
        </>
      ) : !errors && value ? (
        <RiLock2Line fontSize={25} color={theme.palette.grey[300]} />
      ) : (
        <RiPauseLine fontSize={25} color={theme.palette.grey[500]} />
      )}
    </>
  );
};

export default EndAdornment;
