import { createAsyncThunk } from '@reduxjs/toolkit';
import labApi from '../../core/api/lab.api';
import { FindSampleBatchesResponse } from '../../core/api/lab.types';

/* Find Sample Batches */
export const findSampleBatchesThunk = createAsyncThunk(
  'batchList/findSampleBatches',
  async (searchTerm: string, { rejectWithValue }: any) => {
    const response: FindSampleBatchesResponse = await labApi.findSampleBatches(searchTerm);
    if (response.data?.findSampleBatches) {
      return response.data?.findSampleBatches;
    }
    return rejectWithValue(response.errors);
  },
);
