import React from 'react';
import { useStyles } from './component.style';
import clsx from 'classnames';

interface EnvFlagProps {
  env?: string;
  version?: string;
}

function EnvFlag({ env, version }: EnvFlagProps) {
  const { classes } = useStyles();
  return env === 'dev' || env === 'test' ? (
    <span className={classes.root}>
      <span className={classes.flagDot}></span>
      <span className={classes.flagText}>{env}</span>
      <span className={classes.flagVersion}>{version}</span>
      <span className={clsx(classes.flag, { [classes.flagTest]: env === 'test' })}></span>
      <span className={clsx(classes.flag2)}></span>
      <span className={classes.flagShadow}></span>
    </span>
  ) : null;
}

export default EnvFlag;
