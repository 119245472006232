import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormStepper } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../../../../../layout/Inner';
import { AlertError, Panel } from '../../../../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { cryoboxCreateForward } from '../../../../../../../../store/cryoboxCreate';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import FORMS, { MESSAGES } from '../../../../../../../../core/constants/forms.constants';
import { sampleTypeSelector, valuesSelector } from '../../../../../../../../store/cryoboxCreate/selectors';
import { ScanEvent } from '../../../../../../../../components';

import CryoboxGrid from '../../../../components/CryoboxGrid';
import CryoboxSummary from '../../../../components/CryoboxSummary';
import { getAliquotStoredSchema } from '../../../../../../../../core/validation/aliquots.validation';

function PositionsScene() {
  const dispatch = useAppDispatch();
  const sampleType = useAppSelector(sampleTypeSelector);
  const values = useAppSelector(valuesSelector);
  const navigateTo = useNavigate();

  const validationSchema = yup.lazy(() =>
    yup.object({
      aliquotPositions: yup.array().of(getAliquotStoredSchema(sampleType)).min(1, MESSAGES.storageAliquotsRequired),
    }),
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aliquotPositions: [],
      ...values,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(cryoboxCreateForward(values));
      navigateTo(ROUTES.cryoboxCreateFreezer);
    },
  });

  const handleClearAll = () => {
    formik.setFieldValue('aliquotPositions', []);
  };

  const widthInt = parseInt(values?.width || '');
  const heightInt = parseInt(values?.height || '');
  const capacity = widthInt * heightInt;

  const aliquotErrors =
    formik.errors.aliquotPositions && typeof formik.errors.aliquotPositions !== 'string'
      ? formik.errors.aliquotPositions
      : [];
  const aliquotValues = formik.values.aliquotPositions as string[];
  const totalValues = aliquotValues.length;
  const showAliquotError = aliquotErrors.length > 0;
  const scanActive = totalValues < capacity && (!formik.errors.aliquotPositions || totalValues === 0);

  if (!sampleType || !values) {
    return <Navigate to={ROUTES.cryoboxCreate} />;
  }

  return (
    <InnerLayout>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray name="aliquotPositions">
            {({ push, remove }) => (
              <>
                <ScanEvent
                  onScan={(scanCode: string) => {
                    if (scanCode && !aliquotValues.includes(scanCode) && scanActive) {
                      push(scanCode);
                    }
                  }}
                />
                <Box mb={5}>
                  <Typography variant="h4" component="h1" align="center" paragraph mb={5}>
                    Sample Storage
                  </Typography>
                  <FormStepper steps={FORMS.STORAGE_STEPS} activeStep={2} />
                </Box>
                <CryoboxSummary
                  serialNo={formik.values.serialNo}
                  sampleType={formik.values.sampleType}
                  barcode={formik.values.cryoboxBarcode}
                />
                <Panel mb={6} title="Aliquot Positions" intro="Please begin scannning cryovials:">
                  {showAliquotError && (
                    <AlertError title="Scanning paused" description="Please check the rack position marked below" />
                  )}

                  <CryoboxGrid
                    width={widthInt}
                    height={heightInt}
                    onClearAll={handleClearAll}
                    onRemove={remove}
                    values={aliquotValues}
                    errors={aliquotErrors}
                  />
                </Panel>
                <ButtonRow buttonSize="large" forwardColor="primary" showForward={formik.isValid && totalValues > 0} />
              </>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </InnerLayout>
  );
}

export default PositionsScene;
