import React from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Formatter } from '@omnigenbiodata/ui';
import { Grid } from '@mui/material';
import Barcode from 'react-barcode';
import { SampleTypeCodeEnum } from '../../../../../../core/api/lab.types';
import { Panel } from '../../../../../../components';

export interface CryoboxSummaryProps {
  serialNo?: string;
  sampleType?: SampleTypeCodeEnum;
  createdTs?: string;
  barcode?: string;
}

function CryoboxSummary({ serialNo, sampleType, createdTs, barcode }: CryoboxSummaryProps) {
  return (
    <Panel mb={4}>
      <Grid container spacing={2} alignItems="center">
        {sampleType && (
          <Grid item xs="auto">
            <Chip label={sampleType} color="primary" variant="outlined" />
          </Grid>
        )}
        <Grid item xs>
          {serialNo && (
            <Typography component="h2" variant="h5">
              <strong>{serialNo}</strong>
            </Typography>
          )}
          {createdTs && (
            <Typography component="h2" variant="body1">
              Created: <Formatter input={createdTs} format="friendlyDateTime" />
            </Typography>
          )}
        </Grid>
        <Grid item style={{ width: '150px', textAlign: 'center' }}>
          {barcode && <Barcode value={barcode} width={1} height={50} />}
        </Grid>
      </Grid>
    </Panel>
  );
}

export default CryoboxSummary;
