export const linkRGCBarcodeMutation = `
mutation linkRGCBarcode($input: LinkRGCBarcodeInput!) {
    linkRGCBarcode(input: $input) {
        aliquotId
        rgcBarcode
    },
}
`;

export const createSampleBatchMutation = `
mutation createSampleBatch($input: CreateSampleBatchInput!) {
    createSampleBatch(input: $input) {
        batchId
    },
}
`;

export const createCryoboxMutation = `
mutation createCryobox($input: CreateCryoboxInput!) {
    createCryobox(input: $input) {
        serialNo
    },
}
`;

export const updateCryoboxMutation = `
mutation updateCryobox($input: UpdateCryoboxInput!) {
    updateCryobox(input: $input) {
        aliquotPositions
        aliquotPositionTimestamps
        bioBanked
        createdBy
        createdTs
        freezer {
        freezerId
        name
        serialNo
        temp
        }
        freezerShelfNo
        height
        lastUpdatedBy
        rackPositionNo
        sampleType
        serialNo
        cryoboxUuid
        cryoboxBarcode
        shelfRackNo
        updatedTs
        width
    },
}
`;

export const requestSampleExport = `
mutation requestSampleExport {
    requestSampleExport {
        runId
    }
}`;
