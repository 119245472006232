import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  aliquotRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  aliquotColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    fontSize: 11,
    textAlign: 'center',
    height: 55,
    margin: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    position: 'relative',
    transition: 'all .3s linear',
    boxShadow: 'none',
  },
  isSelectable: {
    cursor: 'pointer',
  },
  aliquotIndex: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 5,
    paddingRight: 5,
    position: 'absolute',
    top: 3,
    left: 3,
    borderRadius: '50%',
  },
  aliquotContent: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
  },
  aliquotColumnEmpty: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    background: theme.palette.grey[100],
    color: theme.palette.grey[700],
  },
  aliquotColumnFull: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    boxShadow: '3px 3px 2px rgba(0,0,0,0.2)',
  },
  aliquotColumnError: {
    background: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  tooltipTypog: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIcon: {
    marginRight: 10,
  },
}));
