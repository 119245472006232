import React from 'react';
import { RiBarcodeLine, RiArrowRightLine } from 'react-icons/ri';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { FormInput } from '@omnigenbiodata/ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MESSAGES } from '../../../../../../core/constants/forms.constants';
import { Panel } from '../../../../../../components';
import { REGEX_PRIMARY_OR_ALIQUOT } from '../../../../../../core/validation/aliquots.validation';

interface BatchSearchProps {
  onSearch: (values: object) => void;
  buttonLabel?: string;
  searchTerm?: string;
}

function BatchSearch({ searchTerm = '', buttonLabel = 'Search', onSearch }: BatchSearchProps) {
  const validationSchema = yup.object({
    searchTerm: yup
      .string()
      .required(MESSAGES.sampleSearchTermRequired)
      .matches(REGEX_PRIMARY_OR_ALIQUOT, 'Invalid barcode'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchTerm,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      onSearch(values);
    },
  });

  return (
    <Panel data-testid="batchSearch" mb={2}>
      <Typography component="h2" paragraph variant="h6">
        Sample Batch Search
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <FormInput
              label="Primary ID / Aliquot ID"
              type="text"
              name={`searchTerm`}
              error={formik.errors.searchTerm}
              touched={formik.touched.searchTerm}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.searchTerm}
              margin="none"
              startAdornment={<RiBarcodeLine />}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              type="submit"
              variant="outlined"
              size="large"
              color="primary"
              fullWidth
              sx={{ height: '100%', fontSize: '20px' }}
            >
              {buttonLabel} <RiArrowRightLine />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
}

export default BatchSearch;
