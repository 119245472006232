import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { CryoboxSearchResult, ExportStatus } from '../../core/api/lab.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.cryoboxList.errors && state.cryoboxList.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.cryoboxList.status === StoreStatus.BUSY;

export const searchTermSelector = (state: RootState): string | null => state.cryoboxList.values?.searchTerm || null;

export const freezerIdSelector = (state: RootState): string | null => state.cryoboxList.values?.freezerId || null;

export const bioBankedSelector = (state: RootState): string | null => state.cryoboxList.values?.bioBanked || null;

export const responseSelector = (state: RootState): CryoboxSearchResult | null => state.cryoboxList.response || null;

export const exportIsBusySelector = (state: RootState): boolean => state.cryoboxList.exportStatus === StoreStatus.BUSY;

export const exportResponseSelector = (state: RootState): ExportStatus | null =>
  state.cryoboxList.exportResponse || null;

export const exportErrorSelector = (state: RootState): any | null => state.cryoboxList.exportErrors || null;
