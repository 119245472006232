import React, { ReactNode } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export interface CryoboxTableProps {
  children: ReactNode;
}

function CryoboxTable({ children }: CryoboxTableProps) {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="cryobox table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Serial No.</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center" style={{ minWidth: 250 }}>
              Freezer
            </TableCell>
            <TableCell align="center">Shelf</TableCell>
            <TableCell align="center">Rack</TableCell>
            <TableCell align="center">Position</TableCell>
            <TableCell align="center">Slot</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default CryoboxTable;
