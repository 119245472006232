import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  newCell: {
    animation: '$addRow 1s 1 linear',
    transformOrigin: 'top',
  },
  pendingCell: {
    opacity: 0.2,
  },
  leftCellRed: {
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.error.dark,
  },
  leftCellPurple: {
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: 'purple',
  },
  '@keyframes addRow': {
    '0%': {
      backgroundColor: '#fff',
    },
    '50%': {
      backgroundColor: theme.palette.success.light,
    },
    '100%': {
      backgroundColor: '#fff',
    },
  },
}));
