import React, { ReactNode } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export interface BatchTableProps {
  children: ReactNode;
}

function BatchTable({ children }: BatchTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ minWidth: 300 }}>
              Batch ID
            </TableCell>
            <TableCell align="center" style={{ width: 90 }}>
              Type
            </TableCell>
            <TableCell align="center" style={{ width: 250 }}>
              Created
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default BatchTable;
