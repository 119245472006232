import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormInput, FormSelect, Loader, SelectOption, FormCheckBox } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../../../layout/Inner';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  getCryoboxThunk,
  updateCryoboxThunk,
  hasErrorSelector,
  isBusySelector,
  responseSelector,
  isUpdatedSelector,
} from '../../../../../../store/cryobox';
import { listFreezersThunk, responseSelector as freezerResponseSelector } from '../../../../../../store/freezerList';
import CryoboxSummary from '../../components/CryoboxSummary';
import CryoboxGrid from '../../components/CryoboxGrid';
import { MESSAGES } from '../../../../../../core/constants/forms.constants';
import { Freezer, SampleTypeCodeEnum } from '../../../../../../core/api/lab.types';
import { AlertError, Panel, ScanEvent } from '../../../../../../components';
import Grid from '@mui/material/Grid';
import { AiOutlineNumber } from 'react-icons/ai';
import EditAliquotDialog from './component/EditAliquotDialog';
import AlertSuccess from '../../../../../../components/content/AlertSuccess';
import { getAliquotStoredSchema } from '../../../../../../core/validation/aliquots.validation';
import Box from '@mui/material/Box';
import arrayUtils from '../../../../../../core/utils/arrays';
import { RiBarcodeLine } from 'react-icons/ri';

function CryoboxScene() {
  const { cryoboxUuid } = useParams<any>();
  const [selectedAliquot, setSelectedAliquot] = useState<number | undefined>();
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);
  const isUpdated = useAppSelector(isUpdatedSelector);
  const freezerList = useAppSelector(freezerResponseSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCryoboxThunk(cryoboxUuid || ''));
    if (!freezerList) {
      dispatch(listFreezersThunk());
    }
  }, [cryoboxUuid, freezerList, dispatch]);

  const validationSchema = yup.lazy(() =>
    yup.object({
      freezerId: yup.string().required(MESSAGES.freezerIdRequired),
      freezerShelfNo: yup.string().required(MESSAGES.freezerShelfNoRequired),
      biobanked: yup.boolean(),
      shelfRackNo: yup.string().required(MESSAGES.shelfRackNoRequired),
      rackPositionNo: yup.string().required(MESSAGES.rackPositionNoRequired),
      aliquotPositions: yup.array().of(getAliquotStoredSchema(response?.sampleType as SampleTypeCodeEnum)),
      cryoboxBarcode: yup.string().when('sampleType', {
        is: (sampleType) => sampleType === SampleTypeCodeEnum.WHOLE,
        then: yup.string().required(MESSAGES.cryoboxBarcodeRequired),
      }),
    }),
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sampleType: response?.sampleType || '',
      cryoboxBarcode: response?.cryoboxBarcode || '',
      aliquotPositions: [...(response?.aliquotPositions || [])],
      freezerId: response?.freezer?.freezerId || '',
      freezerShelfNo: response?.freezerShelfNo || '',
      bioBanked: response?.bioBanked || false,
      shelfRackNo: response?.shelfRackNo || '',
      rackPositionNo: response?.rackPositionNo || '',
      serialNo: response?.serialNo || '',
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      if (response) {
        dispatch(updateCryoboxThunk({ ...values, cryoboxUuid: response.cryoboxUuid }));
      }
    },
  });

  const showLoader = isBusy;
  const showGetError = hasError && !isBusy;
  const showGetResult = !isBusy && !hasError && response;

  const widthInt = response?.width;
  const heightInt = response?.height;
  const aliquotErrors =
    formik.errors.aliquotPositions && typeof formik.errors.aliquotPositions !== 'string'
      ? formik.errors.aliquotPositions
      : [];
  const aliquotValues = formik.values.aliquotPositions as string[];
  const aliquotsRemaining = aliquotValues.filter((value) => value !== '');
  const scanActive =
    aliquotValues.length < widthInt! * heightInt! && (!formik.errors.aliquotPositions || aliquotValues.length === 0);

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Cryobox Details
      </Typography>
      {showGetError && <AlertError title="Error" description="Could not load the selected sample batch" />}
      {isUpdated && (
        <AlertSuccess title="Updated" description={`You have successfully updated cryobox ${response?.serialNo}`} />
      )}

      {showGetResult && (
        <FormikProvider value={formik}>
          <Form>
            <FieldArray name="aliquotPositions">
              {({ remove, push }) => (
                <>
                  {selectedAliquot === undefined && (
                    <ScanEvent
                      data-testid="scanner"
                      onScan={(scanCode: string) => {
                        if (scanCode && scanActive && !aliquotValues.includes(scanCode)) {
                          push(scanCode);
                        }
                      }}
                    />
                  )}
                  <CryoboxSummary
                    sampleType={response.sampleType}
                    serialNo={response.serialNo}
                    createdTs={response.createdTs}
                    barcode={response.cryoboxBarcode}
                  />

                  {(!response.cryoboxBarcode || response.cryoboxBarcode === null) && (
                    <Panel mb={6} title="Cryobox">
                      {response.sampleType === SampleTypeCodeEnum.WHOLE && (
                        <AlertError
                          title="Barcode required"
                          description="This cryobox does not currently have a barcode. Please scan or type the barcode into the field below and click 'Save Cryobox'."
                        />
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                          <FormInput
                            label={
                              response.sampleType === SampleTypeCodeEnum.WHOLE
                                ? 'Cryobox Barcode'
                                : 'Cryobox Barcode (Optional)'
                            }
                            type="text"
                            name={`cryoboxBarcode`}
                            error={formik.errors.cryoboxBarcode}
                            touched={formik.touched.cryoboxBarcode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cryoboxBarcode}
                            startAdornment={<RiBarcodeLine />}
                          />
                        </Grid>
                      </Grid>
                    </Panel>
                  )}

                  <Panel mb={6} title="Storage Location">
                    <FormSelect
                      label="Freezer"
                      name={`freezerId`}
                      error={formik.errors.freezerId}
                      touched={formik.touched.freezerId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Freezer"
                      options={
                        freezerList
                          ? (freezerList.map((freezer: Freezer) => ({
                              value: freezer.freezerId,
                              label: `${freezer.name} (${freezer.serialNo})`,
                            })) as SelectOption[])
                          : []
                      }
                      value={formik.values.freezerId}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormInput
                          label="Freezer Shelf No."
                          type="text"
                          name={`freezerShelfNo`}
                          error={formik.errors.freezerShelfNo}
                          touched={formik.touched.freezerShelfNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.freezerShelfNo}
                          startAdornment={<AiOutlineNumber />}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormInput
                          label="Shelf Rack No."
                          type="text"
                          name={`shelfRackNo`}
                          error={formik.errors.shelfRackNo}
                          touched={formik.touched.shelfRackNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.shelfRackNo}
                          startAdornment={<AiOutlineNumber />}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormInput
                          label="Rack Position No."
                          type="text"
                          name={`rackPositionNo`}
                          error={formik.errors.rackPositionNo}
                          touched={formik.touched.rackPositionNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.rackPositionNo}
                          startAdornment={<AiOutlineNumber />}
                        />
                      </Grid>
                    </Grid>
                    <Box pl={2}>
                      <FormCheckBox
                        label="Biobanked"
                        name={`bioBanked`}
                        error={formik.errors.bioBanked}
                        touched={formik.touched.bioBanked}
                        value={formik.values.bioBanked}
                        onChange={formik.handleChange}
                      />
                    </Box>
                  </Panel>
                  <Panel mb={6} title="Aliquot Positions">
                    {aliquotValues && (
                      <CryoboxGrid
                        width={widthInt}
                        height={heightInt}
                        onRemove={remove}
                        onSelect={(index: number) => {
                          setSelectedAliquot(index);
                        }}
                        values={aliquotValues}
                        errors={aliquotErrors}
                        allowRemoveLast={false}
                      />
                    )}
                  </Panel>
                  <ButtonRow
                    buttonSize="large"
                    forwardColor="primary"
                    forwardLabel="Save Cryobox"
                    showForward={formik.isValid && freezerList !== null}
                  />
                  {selectedAliquot !== undefined && (
                    <EditAliquotDialog
                      index={selectedAliquot}
                      value={selectedAliquot !== undefined ? aliquotValues[selectedAliquot] : ''}
                      isOpen={selectedAliquot !== undefined ? true : false}
                      onClose={() => {
                        setSelectedAliquot(undefined);
                      }}
                      onSave={(targetValue: string, targetIndex: number) => {
                        setSelectedAliquot(undefined);
                        if (!aliquotValues.includes(targetValue)) {
                          formik.setFieldValue(
                            'aliquotPositions',
                            arrayUtils.given(aliquotValues).pushToIndex(targetIndex, targetValue).trimEmpties().out(),
                          );
                        }
                      }}
                      onRemove={(targetIndex: number) => {
                        if (aliquotsRemaining.length > 1) {
                          formik.setFieldValue(
                            'aliquotPositions',
                            arrayUtils.given(aliquotValues).replaceAtIndex(targetIndex, '').trimEmpties().out(),
                          );
                        } else formik.setFieldValue('aliquotPositions', []);
                        setSelectedAliquot(undefined);
                      }}
                    />
                  )}
                </>
              )}
            </FieldArray>
          </Form>
        </FormikProvider>
      )}
      <Loader isVisible={showLoader} />
    </InnerLayout>
  );
}

export default CryoboxScene;
