import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormStepper } from '@omnigenbiodata/ui';
import { Navigate, useNavigate } from 'react-router-dom';
import InnerLayout from '../../../../../../../../layout/Inner';
import FORMS from '../../../../../../../../core/constants/forms.constants';
import { Panel, ScanEvent } from '../../../../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { samplesSelector, sampleTypeSelector } from '../../../../../../../../store/batchCreate/selectors';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { AliquotsConfirmList, AliquotsConfirmRow } from './components';
import { batchCreateForward } from '../../../../../../../../store/batchCreate';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { SampleFormValues } from '../../../../../../../../store/batchCreate/types';
import { getAliquotsConfirmSchema } from '../../../../../../../../core/validation/aliquots.validation';

function AliquotsConfirmScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const [scanned, setScanned] = useState<string[]>([]);
  const [mismatches, setMismatches] = useState<string[]>([]);
  const [scannedRows, setScannedRows] = useState<string[][]>([]);
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      samples:
        (samples?.map((sample, index) => {
          return {
            ...sample,
            aliquot1IDConfirm: scannedRows[index] ? scannedRows[index][0] : undefined,
            aliquot1IDMismatch: scannedRows[index] ? mismatches.includes(scannedRows[index][0]) : false,
            aliquot2IDConfirm: scannedRows[index] ? scannedRows[index][1] : undefined,
            aliquot2IDMismatch: scannedRows[index] ? mismatches.includes(scannedRows[index][1]) : false,
          };
        }) as SampleFormValues[]) || [],
    },
    initialTouched: {
      samples:
        samples?.map((sample, index) => {
          return {
            aliquot1IDConfirm: scannedRows[index] && scannedRows[index][0] ? true : false,
            aliquot2IDConfirm: scannedRows[index] && scannedRows[index][1] ? true : false,
            aliquot1IDMismatch: scannedRows[index] && scannedRows[index][0] ? true : false,
            aliquot2IDMismatch: scannedRows[index] && scannedRows[index][1] ? true : false,
          };
        }) || [],
    },
    validationSchema: getAliquotsConfirmSchema(sampleType),
    onSubmit: (values) => {
      dispatch(batchCreateForward(values));
      navigateTo(ROUTES.batchesCreateAliquotsStatus);
    },
  });

  const scanningPaused = useMemo(() => {
    return (
      formik.values.samples
        .filter((sample, index) => {
          const errors = formik.errors.samples ? (formik.errors.samples[index] as any) : {};
          const touched = formik.touched.samples ? (formik.touched.samples[index] as any) : {};

          return (
            (sample.aliquot1IDConfirm && errors?.aliquot1IDConfirm && touched?.aliquot1IDConfirm) ||
            (sample.aliquot2IDConfirm && errors?.aliquot2IDConfirm && touched?.aliquot2IDConfirm)
          );
        }, [])
        .map((sample) => sample.barcode).length > 0
    );
  }, [formik.values, formik.errors, formik.touched]);

  const handleLockCryoCol = (rowIndex: number, colIndex: number) => {
    if (scannedRows[rowIndex][colIndex]) {
      setMismatches([...mismatches, scannedRows[rowIndex][colIndex]]);
    }
  };

  const handleRemoveCryoCol = (rowIndex: number, colIndex: number) => {
    const newScanned = scannedRows.reduce((prev, curr, index) => {
      if (rowIndex === index) {
        if (colIndex === 0 && curr[1]) {
          return [...prev, curr[1]];
        } else if (colIndex === 1 && curr[0]) {
          return [...prev, curr[0]];
        }
        return [...prev];
      }
      return [...prev, curr[0], curr[1]];
    }, []);
    setScanned(newScanned);
    setScannedRows(_.chunk(newScanned, 2));
  };

  const handleScan = (scanCode: string) => {
    if (!scanningPaused) {
      if (
        formik.values.samples.some(
          ({ aliquot1IDConfirm, aliquot2IDConfirm }) =>
            scanCode === aliquot1IDConfirm || scanCode === aliquot2IDConfirm,
        )
      ) {
        setErrors([FORMS.MESSAGES.primarySamplesDuplicate]);
      } else {
        setErrors(undefined);
        const newScanned = [...scanned, scanCode];
        setScanned(newScanned);
        setScannedRows(_.chunk(newScanned, 2));
      }
    }
  };

  if (!sampleType || !samples) {
    return <Navigate to={ROUTES.batchesCreate} />;
  }

  return (
    <InnerLayout>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray name="samples">
            {() => (
              <>
                <ScanEvent data-testid="scanner" onScan={handleScan} />
                <Typography variant="h4" component="h1" align="center" paragraph mb={5}>
                  Sample Processing
                </Typography>
                <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={3} />
                <Panel
                  mb={6}
                  mt={5}
                  title="Aliquot Final Positions"
                  intro="Please scan the labels on the FULL cryovials linked to each primary barcode"
                >
                  {scanningPaused && (
                    <Box mb={4}>
                      <Alert severity="error">
                        The scanned aliquot does not match the sample order before aliquoting. Verify the reason for the
                        mismatch with another member of staff before continuing
                      </Alert>
                    </Box>
                  )}
                  {errors && (
                    <Box mb={4}>
                      <Alert severity="error">{errors[0]}</Alert>
                    </Box>
                  )}
                  {formik.values.samples.length > 0 && (
                    <AliquotsConfirmList>
                      {formik.values.samples.map((sample: SampleFormValues, index: number) => (
                        <AliquotsConfirmRow
                          key={`${sample.barcode}-${index}`}
                          index={index}
                          sampleType={sampleType}
                          onDelete={handleRemoveCryoCol}
                          onLock={handleLockCryoCol}
                        />
                      ))}
                    </AliquotsConfirmList>
                  )}
                </Panel>
                <ButtonRow showForward={formik.isValid} buttonSize="large" forwardColor="primary" />
              </>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </InnerLayout>
  );
}

export default AliquotsConfirmScene;
