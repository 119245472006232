import React from 'react';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Formatter } from '@omnigenbiodata/ui';
import { SampleTypeCodeEnum } from '../../../../../../../../core/api/lab.types';

export interface BatchRowProps {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  createdTs: string;
  editLink: string;
}

function BatchRow({ batchId, sampleType, createdTs, editLink }: BatchRowProps) {
  return (
    <TableRow key={batchId} data-testid="result">
      <TableCell align="left">
        <Link color="primary" to={editLink}>
          <strong>{batchId}</strong>{' '}
        </Link>
      </TableCell>
      <TableCell align="center">{sampleType}</TableCell>
      <TableCell align="center">
        <Formatter input={createdTs} format="friendlyDateTime" />
      </TableCell>
    </TableRow>
  );
}

export default BatchRow;
