import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Form, FormikProvider, FieldArray, useFormik } from 'formik';
import { ButtonRow, FormStepper } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../../../../../layout/Inner';
import { ScanEvent, Panel } from '../../../../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { batchCreateForward } from '../../../../../../../../store/batchCreate';
import { samplesSelector, sampleTypeSelector } from '../../../../../../../../store/batchCreate/selectors';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import FORMS from '../../../../../../../../core/constants/forms.constants';

import { Placeholder, BarcodeList, BarcodeRow } from './components/';
import { SampleFormValues } from '../../../../../../../../store/batchCreate/types';
import { SampleTypeCodeEnum, SampleTypeNameEnum } from '../../../../../../../../core/api/lab.types';
import {
  DEFAULT_PRIMARY_VOLUME,
  MAX_PRIMARY_BARCODES,
  DEFAULT_PRIMARY_STATUS,
  DEFAULT_PRIMARY_VOLUME_WHOLE_BLOOD,
} from '../../../../../../../../core/constants/lab.constants';
import { yupBarcode, yupPrimarySamples } from '../../../../../../../../core/validation/samples.validation';

function PrimaryTubesScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [isAdding, setIsAdding] = useState<boolean>(true);
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      samples: samples || [],
    },
    validateOnMount: true,
    validationSchema: yupPrimarySamples,
    onSubmit: (values) => {
      dispatch(batchCreateForward(values));
      navigateTo(ROUTES.batchesCreateCryoVials);
    },
  });

  if (!sampleType) {
    return <Navigate to={ROUTES.batches} />;
  }

  return (
    <InnerLayout>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray name="samples">
            {({ remove, push }) => (
              <>
                <ScanEvent
                  data-testid="scanner"
                  onScan={(scanCode) => {
                    setIsAdding(true);
                    if (formik.values.samples.length < MAX_PRIMARY_BARCODES) {
                      if (formik.values.samples.map((obj: SampleFormValues) => obj.barcode).includes(scanCode)) {
                        setErrors([FORMS.MESSAGES.primarySamplesDuplicate]);
                      } else {
                        try {
                          yupBarcode.validateSync(scanCode);
                          push({
                            barcode: scanCode,
                            volume:
                              sampleType === SampleTypeCodeEnum.WHOLE
                                ? DEFAULT_PRIMARY_VOLUME_WHOLE_BLOOD
                                : DEFAULT_PRIMARY_VOLUME,
                            condition: DEFAULT_PRIMARY_STATUS,
                          });
                          setErrors(undefined);
                        } catch (e: any) {
                          console.log(e.message);
                        }
                      }
                    }
                  }}
                />

                <Typography variant="h4" component="h1" align="center" paragraph mb={5}>
                  Sample Processing
                </Typography>
                <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={1} />
                {sampleType && (
                  <Panel
                    mb={6}
                    mt={5}
                    title="Primary Tubes"
                    intro={`Please scan the barcode labels of the primary sample tubes for **${SampleTypeNameEnum[sampleType]}** you are aliquotting in the order they are arranged in the tube
          rack (maximum ${MAX_PRIMARY_BARCODES} samples):`}
                  >
                    {errors && (
                      <Box mb={2}>
                        <Alert severity="error">{errors[0]}</Alert>
                      </Box>
                    )}
                    {(!formik.values.samples || formik.values.samples.length === 0) && <Placeholder />}
                    {formik.values.samples.length > 0 && (
                      <BarcodeList>
                        {formik.values.samples.map((sample: SampleFormValues, index: number) => (
                          <BarcodeRow
                            index={index}
                            key={`${sample.barcode}-${index}`}
                            onDelete={() => {
                              setErrors(undefined);
                              setIsAdding(false);
                              remove(index);
                            }}
                            isAdding={isAdding}
                            sampleType={sampleType}
                          />
                        ))}
                      </BarcodeList>
                    )}
                  </Panel>
                )}
                <ButtonRow showForward={formik.isValid} buttonSize="large" forwardColor="primary" />
              </>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </InnerLayout>
  );
}

export default PrimaryTubesScene;
