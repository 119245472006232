import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

export interface AlertErrorProps {
  title?: string;
  description?: string;
}

function AlertError({ description, title }: AlertErrorProps) {
  return (
    <Box mb={2}>
      <Alert severity="error">
        {title && (
          <>
            <strong>{title}</strong>{' '}
          </>
        )}{' '}
        {description}
      </Alert>
    </Box>
  );
}

export default AlertError;
