import React, { useMemo } from 'react';
import { RiCheckboxBlankCircleLine, RiBarcodeLine, RiAlertLine } from 'react-icons/ri';
import { FaTrashAlt } from 'react-icons/fa';
import clsx from 'classnames';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { theme } from '@omnigenbiodata/ui';
import { useStyles } from './component.styles';
import Box from '@mui/material/Box';

interface ScannedCellProps {
  allowRemoval?: boolean;
  hasError?: boolean | string;
  onRemove?: (index: number) => void;
  onSelect?: (index: number) => void;
  position: number;
  totalPositions?: number;
  value?: string;
}

function ScannedCell({
  allowRemoval = true,
  hasError = false,
  onRemove,
  onSelect,
  position,
  totalPositions = 0,
  value,
}: ScannedCellProps) {
  const { classes } = useStyles();

  const isLastScanned = useMemo(() => position === totalPositions, [position, totalPositions]);

  return (
    <div
      key={`scanned-cell-${position}`}
      className={clsx(classes.aliquotColumn, {
        [classes.aliquotColumnEmpty]: !value && !hasError,
        [classes.aliquotColumnFull]: value && !hasError,
        [classes.aliquotColumnError]: hasError,
        [classes.isSelectable]: onSelect && value !== undefined,
      })}
      onClick={() => {
        if (onSelect && value !== undefined) {
          onSelect(position - 1);
        }
      }}
      data-testid={hasError ? 'aliquot-error' : `aliquot`}
    >
      <div className={clsx(classes.aliquotIndex)}>{position}</div>

      <div className={clsx(classes.aliquotContent)} data-testid={`scanned-cell-${position}`}>
        {value && (
          <Tooltip
            arrow
            title={
              <Box p={2} alignItems="middle">
                <Typography variant="body1" component="span" className={classes.tooltipTypog}>
                  {!hasError && <RiBarcodeLine size={25} className={classes.tooltipIcon} />}
                  {hasError && <RiAlertLine size={25} className={classes.tooltipIcon} />} {value}
                </Typography>
              </Box>
            }
            aria-label={value as any}
            placement="top"
          >
            <div data-testid={hasError ? 'invalid-aliquot' : `valid-aliquot`}>
              {(!isLastScanned || !allowRemoval) && value && (
                <IconButton aria-label="Valid Aliquot" size="large">
                  <RiBarcodeLine
                    color={theme.palette.common.white}
                    title={value as string}
                    fontSize={30}
                    alignmentBaseline="middle"
                  />
                </IconButton>
              )}
              {isLastScanned && allowRemoval && (
                <IconButton
                  data-testid="last-scanned"
                  aria-label="Delete Last Tube"
                  size="large"
                  onClick={() => {
                    if (onRemove) {
                      onRemove(position - 1);
                    }
                  }}
                >
                  <FaTrashAlt fontSize={20} color={theme.palette.common.white} />
                </IconButton>
              )}
            </div>
          </Tooltip>
        )}
        {!value && <RiCheckboxBlankCircleLine color="grey" />}
      </div>
    </div>
  );
}

export default ScannedCell;
