import { createSlice } from '@reduxjs/toolkit';
import { CryoboxListState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { findCryoboxesThunk, getSampleExportThunk } from './thunks';

// Initial State
const initialState: CryoboxListState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  response: null,
  exportErrors: null,
  exportStatus: StoreStatus.IDLE,
  exportResponse: null,
};

// Slice
export const cryoboxListSlice = createSlice({
  name: 'cryoboxList',
  initialState,
  reducers: {
    setValues: (state, action) => ({
      ...state,
      values: action.payload,
    }),
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(findCryoboxesThunk.pending.type, (state: CryoboxListState) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(findCryoboxesThunk.fulfilled.type, (state: CryoboxListState, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    });
    builder.addCase(findCryoboxesThunk.rejected.type, (state: CryoboxListState, action: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    });
    builder.addCase(getSampleExportThunk.pending.type, (state: CryoboxListState) => {
      state.exportStatus = StoreStatus.BUSY;
      state.exportErrors = null;
      state.exportResponse = null;
    });

    builder.addCase(getSampleExportThunk.fulfilled.type, (state: CryoboxListState, action: any) => {
      state.exportStatus = StoreStatus.IDLE;
      state.exportResponse = action.payload;
      state.exportErrors = null;
    });

    builder.addCase(getSampleExportThunk.rejected.type, (state: CryoboxListState, { payload }: any) => {
      state.exportStatus = StoreStatus.ERROR;
      state.exportErrors = payload;
      state.exportResponse = null;
    });
  },
});

// Action creators
export const { reset, setValues } = cryoboxListSlice.actions;
export * from './thunks';
export * from './selectors';
export default cryoboxListSlice.reducer;
