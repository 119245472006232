import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '@omnigenbiodata/ui';
import ROUTES from '../../core/constants/routes.constants';

function Auth() {
  const { resetStatus, isAuthenticated } = useAuth();

  const [resetCount, setResetCount] = useState(0);

  useEffect(() => {
    if (resetCount < 1) {
      resetStatus();
      setResetCount(resetCount + 1);
    }
  }, [resetStatus, resetCount]);

  if (isAuthenticated) {
    return <Navigate to={ROUTES.portal} />;
  }

  return <Outlet />;
}

export default Auth;
