import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { Loader } from '@omnigenbiodata/ui';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  findCryoboxesThunk,
  hasErrorSelector,
  isBusySelector,
  searchTermSelector,
  responseSelector,
  setValues,
  freezerIdSelector,
  bioBankedSelector,
  exportErrorSelector,
} from '../../../../../../store/cryoboxList';
import { responseSelector as freezerResponseSelector } from '../../../../../../store/freezerList';
import InnerLayout from '../../../../../../layout/Inner';
import StorageSearch from '../../../Home/components/StorageSearch';
import AlertNoResults from './components/AlertNoResults';
import CryoboxTable from './components/CryoboxTable';
import CryoboxRow from './components/CryoboxRow';
import ROUTES from '../../../../../../core/constants/routes.constants';
import { Cryobox } from '../../../../../../core/api/lab.types';
import { AlertError } from '../../../../../../components';
import { listFreezersThunk } from '../../../../../../store/freezerList';
import ExportButton from './components/ExportButton';
import { useTranslation } from 'react-i18next';

function ListScene() {
  const { t } = useTranslation('portal');
  const searchTerm = useAppSelector(searchTermSelector);
  const freezerId = useAppSelector(freezerIdSelector);
  const bioBanked = useAppSelector(bioBankedSelector);

  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const exportError = useAppSelector(exportErrorSelector);
  const cryoboxes = useAppSelector(responseSelector);
  const freezerList = useAppSelector(freezerResponseSelector);
  const [currentPage, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchTerm || freezerId || bioBanked) {
      dispatch(
        findCryoboxesThunk({
          searchTerm: searchTerm || '',
          freezerId: freezerId || '',
          bioBanked: bioBanked || '',
          offset: currentPage * rowsPerPage,
          limit: rowsPerPage,
        }),
      );
    }
    if (!freezerList) {
      dispatch(listFreezersThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, freezerId, bioBanked, freezerList, currentPage, rowsPerPage, dispatch]);

  const handleCryoboxSearch = (values: any) => {
    setPage(0);
    dispatch(setValues(values));
  };

  const handlePagination = (event: any, page: any) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showLoader = isBusy;
  const showError = hasError && !isBusy;
  const showResults = !isBusy && !hasError && cryoboxes && cryoboxes.count > 0;
  const showNoResults = !isBusy && !hasError && cryoboxes && cryoboxes.count === 0;

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Cryobox Results
      </Typography>
      <StorageSearch
        showTitle={false}
        onSearch={handleCryoboxSearch}
        searchTerm={searchTerm || ''}
        freezerId={freezerId || ''}
        freezerList={freezerList || []}
        bioBanked={bioBanked || ''}
      />
      {showError && (
        <AlertError
          title={t('alerts.cryoboxListFailed.title') as string}
          description={t('alerts.cryoboxListFailed.text') as string}
        />
      )}
      {exportError && <AlertError title={t('alerts.exportFailed') as string} description={exportError[0].message} />}
      {showNoResults && <AlertNoResults />}
      {showResults && (
        <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Typography
            style={{
              flex: '1 1 100%',
            }}
            variant="body1"
            component="div"
          >
            {cryoboxes.count} {cryoboxes.count > 1 ? 'cryoboxes' : 'cryobox'} found
          </Typography>
          <ExportButton />
        </Toolbar>
      )}
      {showResults && (
        <Paper>
          <CryoboxTable>
            {cryoboxes.data.map((cryoboxRow: Cryobox, rowNum: number) => (
              <CryoboxRow
                key={`${cryoboxRow.serialNo}-${rowNum}`}
                serialNo={cryoboxRow.serialNo}
                sampleType={cryoboxRow.sampleType}
                freezerName={cryoboxRow.freezer.name}
                freezerShelfNo={cryoboxRow.freezerShelfNo}
                shelfRackNo={cryoboxRow.shelfRackNo}
                rackPositionNo={cryoboxRow.rackPositionNo}
                aliquotPositions={cryoboxRow.aliquotPositions}
                searchTerm={searchTerm}
                editLink={ROUTES.cryoboxEdit.replace(':cryoboxUuid', cryoboxRow.cryoboxUuid)}
              />
            ))}
          </CryoboxTable>
          {cryoboxes.count > 10 && (
            <TablePagination
              component="div"
              count={cryoboxes.count}
              rowsPerPageOptions={[10, 20, 50]}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handlePagination}
              onRowsPerPageChange={handleRowsPerPageChange}
              SelectProps={{ id: 'change-page' }}
            />
          )}
        </Paper>
      )}
      <Loader isVisible={showLoader} />
    </InnerLayout>
  );
}

export default ListScene;
