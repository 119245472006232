import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthRoute } from '@omnigenbiodata/ui';
import ROUTES from '../core/constants/routes.constants';
import Authentication from './Authentication';
import Login from './Authentication/scenes/Login';
import MultiFactor from './Authentication/scenes/MultiFactor';
import NewPassword from './Authentication/scenes/NewPassword';
import ResetPassword from './Authentication/scenes/ResetPassword';
import SubmitResetPassword from './Authentication/scenes/SubmitResetPassword';
import Logout from './Authentication/scenes/Logout';
import Portal from './Portal';
import HomeScene from './Portal/scenes/Home';
import ProcessingScene from './Portal/scenes/Processing';
import ProcessingListScene from './Portal/scenes/Processing/scenes/List';
import ProcessingEditScene from './Portal/scenes/Processing/scenes/Edit';
import ProcessingCreateScene from './Portal/scenes/Processing/scenes/Create';
import ProcessingSampleTypeScene from './Portal/scenes/Processing/scenes/Create/scenes/SampleType';
import ProcessingPrimaryTubesScene from './Portal/scenes/Processing/scenes/Create/scenes/PrimaryTubes';
import ProcessingCryoVialsScene from './Portal/scenes/Processing/scenes/Create/scenes/CryoVials';
import ProcessingStartAliquottingScene from './Portal/scenes/Processing/scenes/Create/scenes/StartAliquotting';
import ProcessingAliquotsConfirmScene from './Portal/scenes/Processing/scenes/Create/scenes/AliquotsConfirm';
import ProcessingAliquotsStatusScene from './Portal/scenes/Processing/scenes/Create/scenes/AliquotsStatus';
import ProcessingCompleteScene from './Portal/scenes/Processing/scenes/Create/scenes/Complete';
import StorageScene from './Portal/scenes/Storage';
import StorageListScene from './Portal/scenes/Storage/scenes/List';
import StorageCreateScene from './Portal/scenes/Storage/scenes/Create';
import StorageCompleteScene from './Portal/scenes/Storage/scenes/Create/scenes/Complete';
import StorageCryoBoxScene from './Portal/scenes/Storage/scenes/Create/scenes/CryoBox';
import StorageFreezerScene from './Portal/scenes/Storage/scenes/Create/scenes/Freezer';
import StorageSampleTypeScene from './Portal/scenes/Storage/scenes/Create/scenes/SampleType';
import StorageAliquotsScene from './Portal/scenes/Storage/scenes/Create/scenes/Aliquots';
import StorageEditScene from './Portal/scenes/Storage/scenes/Edit';
import LinkingScene from './Portal/scenes/Linking';

function Scenes() {
  return (
    <Routes>
      <Route path={ROUTES.auth} element={<Authentication />}>
        <Route path={ROUTES.auth} element={<Login />} />
        <Route path={ROUTES.authMultiFactor} element={<MultiFactor />} />
        <Route path={ROUTES.authNewPassword} element={<NewPassword />} />
        <Route path={ROUTES.authResetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.authSubmitResetPassword} element={<SubmitResetPassword />} />
        <Route path={ROUTES.authSignOut} element={<Logout />} />
      </Route>
      <Route path={ROUTES.portal} element={<AuthRoute path={ROUTES.portal} element={Portal} />}>
        <Route path={ROUTES.linking} element={<LinkingScene />} />
        <Route path={ROUTES.cryobox} element={<StorageScene />}>
          <Route path={ROUTES.cryoboxCreate} element={<StorageCreateScene />}>
            <Route path={ROUTES.cryoboxCreateComplete} element={<StorageCompleteScene />} />
            <Route path={ROUTES.cryoboxCreateFreezer} element={<StorageFreezerScene />} />
            <Route path={ROUTES.cryoboxCreateAliquots} element={<StorageAliquotsScene />} />
            <Route path={ROUTES.cryoboxCreateBox} element={<StorageCryoBoxScene />} />
            <Route path={ROUTES.cryoboxCreate} element={<StorageSampleTypeScene />} />
          </Route>
          <Route path={ROUTES.cryobox} element={<StorageListScene />} />
          <Route path={ROUTES.cryoboxEdit} element={<StorageEditScene />} />
        </Route>
        <Route path={ROUTES.batches} element={<ProcessingScene />}>
          <Route path={ROUTES.batches} element={<ProcessingListScene />} />
          <Route path={ROUTES.batchesCreate} element={<ProcessingCreateScene />}>
            <Route path={ROUTES.batchesCreateComplete} element={<ProcessingCompleteScene />} />
            <Route path={ROUTES.batchesCreateAliquotsStatus} element={<ProcessingAliquotsStatusScene />} />
            <Route path={ROUTES.batchesCreateAliquots2} element={<ProcessingAliquotsConfirmScene />} />
            <Route path={ROUTES.batchesCreateAliquotting} element={<ProcessingStartAliquottingScene />} />
            <Route path={ROUTES.batchesCreateCryoVials} element={<ProcessingCryoVialsScene />} />
            <Route path={ROUTES.batchesCreatePrimaryTubes} element={<ProcessingPrimaryTubesScene />} />
            <Route path={ROUTES.batchesCreate} element={<ProcessingSampleTypeScene />} />
          </Route>
          <Route path={ROUTES.batchesEdit} element={<ProcessingEditScene />} />
        </Route>
        <Route path={ROUTES.portal} element={<HomeScene />} />
      </Route>
      <Route path="*" element={<Navigate to={ROUTES.portal} replace />} />
    </Routes>
  );
}

export default Scenes;
