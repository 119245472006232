import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { Cryobox } from '../../core/api/lab.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.cryobox.errors && state.cryobox.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.cryobox.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Cryobox | null => state.cryobox.response;

export const isUpdatedSelector = (state: RootState): boolean | null => state.cryobox.isUpdated;
