import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Scenes from './scenes';
import { AuthProvider, ScrollToTop, AuthModal } from '@omnigenbiodata/ui';
import ROUTES from './core/constants/routes.constants';
import ENV from './core/constants/environment.constants';
import EnvFlag from './components/content/EnvFlag';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider authPath={ROUTES.auth} postLoginPath={ROUTES.root} postLogoutPath={ROUTES.authSignOut}>
        <Scenes />
        <AuthModal message="Your session will expire in 5 minutes. Please ensure you have completed your work, or sign out now." />
        {process.env.REACT_APP_ENV !== 'production' && <EnvFlag env={ENV.ENVIRONMENT} version={ENV.VERSION} />}
      </AuthProvider>
    </Router>
  );
}

export default App;
