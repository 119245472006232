import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {},
  borderLeft: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[300],
  },
  compact: {
    paddingTop: 2,
    paddingBottom: 2,
  },
}));
