const arrayUtils = {
  array: [] as any[],
  given: function (array: any[]) {
    this.array = array;
    return this;
  },
  out: function () {
    return this.array;
  },
  trimEmpties: function () {
    this.array = this.array.map((val) => {
      if (val === null) {
        return '';
      }
      return val;
    });

    let lastVal = this.array.slice(-1).toString();
    while (lastVal === '') {
      this.array.pop();
      lastVal = this.array.slice(-1).toString();
    }

    return this;
  },
  replaceAtIndex: function (onIndex: number, replacement: any) {
    this.array = this.array.map((currentValue, currentIndex) => {
      if (onIndex !== currentIndex) {
        return currentValue;
      } else {
        return replacement;
      }
    });
    return this;
  },
  pushToIndex: function (onIndex: number, replacement: any) {
    this.array = this.array.map((item, index) => {
      if (index === onIndex) {
        return replacement;
      }
      return item;
    });
    return this;
  },
};

export default arrayUtils;
