import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MainLayout from '../../../../layout/Main';
import ROUTES from '../../../../core/constants/routes.constants';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setValues } from '../../../../store/batchList';
import { setValues as setCryoboxesValues } from '../../../../store/cryoboxList';
import { responseSelector as freezerResponseSelector } from '../../../../store/freezerList';
import BatchSearch from './components/BatchSearch';
import StorageSearch from './components/StorageSearch';
import HomeOptions from './components/HomeOptions';
import { BatchListFormValues } from '../../../../store/batchList/types';
import { listFreezersThunk } from '../../../../store/freezerList';

function HomeScene() {
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();
  const freezerList = useAppSelector(freezerResponseSelector);

  useEffect(() => {
    if (!freezerList) {
      dispatch(listFreezersThunk());
    }
  }, [dispatch, freezerList]);

  const handleBatchSearch = (values: BatchListFormValues) => {
    dispatch(setValues(values));
    navigateTo(ROUTES.batches);
  };

  const handleStorageSearch = (values: any) => {
    dispatch(setCryoboxesValues(values));
    navigateTo(ROUTES.cryobox);
  };

  return (
    <MainLayout showLogout>
      <Box mb={6}>
        <Typography variant="h3" component="h1" align="center" paragraph>
          Laboratory Portal
        </Typography>
      </Box>
      <HomeOptions />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <BatchSearch onSearch={handleBatchSearch} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <StorageSearch onSearch={handleStorageSearch} freezerList={freezerList || []} />
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default HomeScene;
