import * as yup from 'yup';
import { MESSAGES } from '../constants/forms.constants';
import { MAX_PRIMARY_BARCODES, MIN_PRIMARY_BARCODES, REGEX_PRIMARY_BARCODE_BROAD } from '../constants/lab.constants';

/* Barcode Schemas */
export const yupBarcode = yup
  .string()
  .required(MESSAGES.primarySampleRequired)
  .matches(REGEX_PRIMARY_BARCODE_BROAD, MESSAGES.primarySamplesInvalid);

export const yupVolume = yup
  .number()
  .required(MESSAGES.primarySamplesVolume)
  .moreThan(-0.1, MESSAGES.primarySamplesVolumePositive);

export const yupPrimaryCondition = yup.string().required(MESSAGES.primarySamplesCondition);

export const yupPrimarySamples = yup.object().shape({
  samples: yup
    .array()
    .min(MIN_PRIMARY_BARCODES, MESSAGES.primarySamplesEmpty)
    .max(MAX_PRIMARY_BARCODES, MESSAGES.primarySamplesFull)
    .of(
      yup.object().shape({
        barcode: yupBarcode,
        volume: yupVolume,
        condition: yupPrimaryCondition,
      }),
    ),
});
