export type StatusOptions = {
  [key: string]: string;
};

export const PRIMARY_STATUSES: StatusOptions = {
  SNC: 'Sample Not Collected',
  SHV: 'Short Volume',
  CLT: 'Clotted',
  BKV: 'Broken or cracked vial',
  CTM: 'Contaminated',
  DMG: 'Damaged container',
  EQF: 'Equipment failure',
  EXP: 'Expired',
  FRO: 'Frozen',
  HEM: 'Hemolyzed',
  ICT: 'Icteric',
  INT: 'Incorrect Tube',
  LBE: 'Laboratory error',
  LKD: 'Leaked',
  LIP: 'Lipemic',
  LYS: 'Lysed',
  PST: 'Processed after specified time',
  SNP: 'Sample Not Processed',
  SAT: 'Satisfactory',
  TNO: 'Temperature Not Optimal',
};

export const DEFAULT_PRIMARY_STATUS = 'SAT';

export const ALIQUOT_STATUSES: StatusOptions = {
  SHV: 'Short volume',
  CLT: 'Clotted',
  CTM: 'Contaminated',
  HEM: 'Hemolyzed',
  SAT: 'Satisfactory',
};
export const DEFAULT_ALIQUOT_STATUS = 'SAT';
export const DEFAULT_ALIQUOT_STATUS_NOT_COLLECTED = 'SHV';

export const ALIQUOT_POSTFIXES: any = {
  WHOLE: ['01', '02'],
  SERUM: ['03', '04'],
  PLASMA: ['05', '06'],
};
export const MIN_PRIMARY_BARCODES = 1;
export const MAX_PRIMARY_BARCODES = 10;
export const PRIMARY_BARCODE_LENGTH = 7;
export const ALIQUOT_BARCODE_LENGTH = 9;

export const DEFAULT_PRIMARY_VOLUME = 6;
export const DEFAULT_PRIMARY_VOLUME_WHOLE_BLOOD = 2;

export const DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME = 0.5;
export const DEFAULT_ALIQUOT_VOLUME = 1;

export const REGEX_PRIMARY_BARCODE_BROAD = /^([0-9]{7})$/;
