import { API as MOCK_AMPLIFY_API } from '../../__mocks__/aws-amplify';
import { API as AMPLIFY_API } from 'aws-amplify';
import {
  createSampleBatchMutation,
  createCryoboxMutation,
  updateCryoboxMutation,
  linkRGCBarcodeMutation,
  requestSampleExport,
} from '../graphql/mutations';
import {
  findCryoboxesQuery,
  findSampleBatchesQuery,
  getSampleBatchQuery,
  getCryoboxQuery,
  listFreezersQuery,
  getSampleExportStatusQuery,
} from '../graphql/queries';
import {
  CreateCryoBoxInput,
  CreateSampleBatchInput,
  LinkRGCBarcodeInput,
  RequestSampleResponse,
  SampleExportStatusResponse,
  UpdateCryoBoxInput,
} from './lab.types';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const participantApi = {
  getSampleBatch: async (batchID: string): Promise<any> => {
    try {
      return await API.graphql({
        query: getSampleBatchQuery,
        variables: {
          batchID,
        },
      });
    } catch (error) {
      return error;
    }
  },
  findSampleBatches: async (searchTerm: string): Promise<any> => {
    try {
      return await API.graphql({
        query: findSampleBatchesQuery,
        variables: {
          searchTerm,
        },
      });
    } catch (error) {
      return error;
    }
  },
  createSampleBatch: async (inputs: CreateSampleBatchInput): Promise<any> => {
    try {
      return await API.graphql({
        query: createSampleBatchMutation,
        variables: {
          input: inputs,
        },
      });
    } catch (error) {
      return error;
    }
  },
  getCryobox: async (cryoboxUuid: string): Promise<any> => {
    try {
      return await API.graphql({
        query: getCryoboxQuery,
        variables: {
          cryoboxUuid,
        },
      });
    } catch (error) {
      return error;
    }
  },
  findCryoboxes: async (
    searchTerm: string,
    freezerId: string,
    bioBanked: string,
    offset?: number,
    limit?: number,
  ): Promise<any> => {
    try {
      return await API.graphql({
        query: findCryoboxesQuery,
        variables: {
          bioBanked: bioBanked === 'true',
          freezerId,
          searchTerm,
          offset,
          limit,
        },
      });
    } catch (error) {
      return error;
    }
  },
  createCryoBox: async (values: CreateCryoBoxInput): Promise<any> => {
    try {
      return await API.graphql({
        query: createCryoboxMutation,
        variables: {
          input: {
            serialNo: values.serialNo,
            cryoboxBarcode: values.cryoboxBarcode || undefined,
            height: parseInt(values.height),
            width: parseInt(values.width),
            sampleType: values.sampleType,
            aliquotPositions: values.aliquotPositions,
            bioBanked: false,
            freezerId: values.freezerId,
            freezerShelfNo: values.freezerShelfNo,
            shelfRackNo: values.shelfRackNo,
            rackPositionNo: values.rackPositionNo,
          },
        },
      });
    } catch (error) {
      return error;
    }
  },
  updateCryobox: async (values: UpdateCryoBoxInput): Promise<any> => {
    try {
      return await API.graphql({
        query: updateCryoboxMutation,
        variables: {
          input: {
            cryoboxUuid: values.cryoboxUuid,
            aliquotPositions: values.aliquotPositions,
            cryoboxBarcode: values.cryoboxBarcode || undefined,
            freezerId: values.freezerId,
            freezerShelfNo: values.freezerShelfNo,
            shelfRackNo: values.shelfRackNo,
            rackPositionNo: values.rackPositionNo,
            bioBanked: values.bioBanked,
          },
        },
      });
    } catch (error) {
      return error;
    }
  },
  listFreezers: async (): Promise<any> => {
    try {
      return await API.graphql({
        query: listFreezersQuery,
      });
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  linkRGCBarcode: async (inputs: LinkRGCBarcodeInput): Promise<any> => {
    try {
      return await API.graphql({
        query: linkRGCBarcodeMutation,
        variables: {
          input: inputs,
        },
      });
    } catch (error) {
      return error;
    }
  },
  getSampleExportStatus: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getSampleExportStatusQuery,
      })) as SampleExportStatusResponse;
    } catch (error) {
      return error as SampleExportStatusResponse;
    }
  },
  requestSampleExport: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: requestSampleExport,
      })) as RequestSampleResponse;
    } catch (error) {
      return error as RequestSampleResponse;
    }
  },
};

export default participantApi;
