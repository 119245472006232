import React from 'react';
import Alert from '@mui/material/Alert';

function AlertNoResults() {
  return (
    <Alert severity="info">
      <strong>No matches</strong> <br />
      There were no sample batches matching your search term. Please check that you have entered a valid primary ID or
      aliquot ID.
    </Alert>
  );
}

export default AlertNoResults;
