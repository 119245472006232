import { createAsyncThunk } from '@reduxjs/toolkit';
import labApi from '../../core/api/lab.api';
import { LinkRGCBarcodeResponse } from '../../core/api/lab.types';

/* Get Sample Batch */
export const linkRGCBarcodeThunk = createAsyncThunk(
  'rgcLinking/linkRGCThunk',
  async (values: any, { rejectWithValue }: any) => {
    const response: LinkRGCBarcodeResponse = await labApi.linkRGCBarcode(values);
    if (response.data?.linkRGCBarcode) {
      return response.data?.linkRGCBarcode;
    }
    return rejectWithValue(response.errors);
  },
);
