import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { BsCheck } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ButtonRow, FormStepper, theme } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../../../../../layout/Inner';
import Panel from '../../../../../../../../components/layout/Panel';
import { useAppDispatch } from '../../../../../../../../store';
import { batchCreateForward } from '../../../../../../../../store/batchCreate';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import FORMS, { MESSAGES } from '../../../../../../../../core/constants/forms.constants';
import { SampleTypeCodeEnum, SampleTypeNameEnum } from '../../../../../../../../core/api/lab.types';

function SampleTypeScene() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();

  const validationSchema = yup.object({
    sampleType: yup.string().required(MESSAGES.sampleTypeRequired),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sampleType: '',
      samples: [],
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      dispatch(batchCreateForward(values));
      navigateTo(ROUTES.batchesCreatePrimaryTubes);
    },
  });

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Typography variant="h4" component="h1" align="center" paragraph mb={5}>
            Sample Processing
          </Typography>
          <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={0} />
        </Box>
        <Panel mb={6} mt={5} title="Sample Type" intro="Please select the sample type you are processing:">
          {formik.errors.sampleType && formik.touched.sampleType && (
            <Box mb={2}>
              <Alert severity="error">{formik.errors.sampleType}</Alert>
            </Box>
          )}

          <Grid container spacing={2}>
            {Object.values(SampleTypeCodeEnum).map((sampleKey) => (
              <Grid item xs={12} lg={4} key={sampleKey}>
                <Button
                  variant={formik.values.sampleType === sampleKey ? 'contained' : 'outlined'}
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    formik.setFieldValue('sampleType', sampleKey as SampleTypeCodeEnum);
                  }}
                  endIcon={
                    formik.values.sampleType === sampleKey ? (
                      <BsCheck size={30} color={theme.palette.primary.main} />
                    ) : undefined
                  }
                  centerRipple
                >
                  {SampleTypeNameEnum[sampleKey]}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Panel>
        <ButtonRow showBack={false} showForward={formik.isValid} buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default SampleTypeScene;
