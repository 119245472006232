import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  leftCellRed: {
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.error.dark,
  },
  leftCellPurple: {
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: 'purple',
  },
}));
