import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../../../../../../store';

import {
  exportIsBusySelector,
  exportResponseSelector,
  getSampleExportThunk,
} from '../../../../../../../../store/cryoboxList';
import { GrDocumentCsv } from 'react-icons/gr';

function ExportButton() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const exportLoading = useAppSelector(exportIsBusySelector);
  const exportResponse = useAppSelector(exportResponseSelector);

  const createExport = () => {
    if (!exportLoading) {
      dispatch(getSampleExportThunk());
    }
  };

  const downloadExport = () => {
    if (exportResponse?.exportLink) {
      window.open(exportResponse.exportLink, '_blank');
    }
  };

  return (
    <Box display="flex">
      <Button
        fullWidth
        startIcon={<GrDocumentCsv />}
        onClick={exportResponse?.exportLink ? downloadExport : createExport}
      >
        <Box sx={{ minWidth: '110px' }} display="flex" justifyContent="center">
          {exportLoading ? (
            <CircularProgress data-testid="export-loader" size={20} />
          ) : exportResponse?.exportLink ? (
            t('buttons.downloadExport')
          ) : (
            t('buttons.createExport')
          )}
        </Box>
      </Button>
    </Box>
  );
}

export default ExportButton;
