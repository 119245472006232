import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { SampleFormValues } from './types';
import { SampleBatch, SampleTypeCodeEnum } from '../../core/api/lab.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.batchCreate.errors && state.batchCreate.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.batchCreate.status === StoreStatus.BUSY;

export const sampleTypeSelector = (state: RootState): SampleTypeCodeEnum | null =>
  state.batchCreate.values?.sampleType || null;

export const samplesSelector = (state: RootState): SampleFormValues[] | null =>
  state.batchCreate.values?.samples || null;

export const responseSelector = (state: RootState): SampleBatch | null => state.batchCreate.response || null;
