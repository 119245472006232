import React from 'react';
import { RiBarcodeLine, RiArrowRightLine } from 'react-icons/ri';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { FormInput } from '@omnigenbiodata/ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Panel } from '../../../../../../components';
import { LinkRGCBarcodeInput } from '../../../../../../core/api/lab.types';
import { REGEX_PRIMARY_OR_ALIQUOT } from '../../../../../../core/validation/aliquots.validation';
import { MESSAGES } from '../../../../../../core/constants/forms.constants';
import EndAdornment from '../EndAdornment';

interface LinkingFormProps {
  onLink: (values: LinkRGCBarcodeInput) => void;
  onDelete: (index: number) => void;
  buttonLabel?: string;
  aliquotId?: string;
  rgcBarcode?: string;
}

function LinkingForm({ aliquotId = '', rgcBarcode = '', buttonLabel = 'Link', onLink, onDelete }: LinkingFormProps) {
  const validationSchema = yup.object({
    aliquotId: yup
      .string()
      .trim()
      .required(MESSAGES.aliquotRequired)
      .matches(REGEX_PRIMARY_OR_ALIQUOT, MESSAGES.aliquotInvalid),
    rgcBarcode: yup.string().required(MESSAGES.rgcRequired).length(10, MESSAGES.rgcInvalid),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aliquotId,
      rgcBarcode,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      onLink(values);
    },
  });

  return (
    <Panel mb={2}>
      <Typography component="h2" variant="h6">
        New aliquot link
      </Typography>
      <Typography component="p" paragraph variant="body1">
        Start by scanning your aliquot barcode followed by a valid RGC barcode.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <FormInput
              label="Aliquot ID"
              type="text"
              name={`aliquotId`}
              error={formik.errors.aliquotId}
              touched={formik.touched.aliquotId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.aliquotId}
              margin="none"
              startAdornment={<RiBarcodeLine />}
              readOnly
              endAdornment={
                <EndAdornment
                  isActive={formik.values.aliquotId === '' && formik.values.rgcBarcode === '' ? true : false}
                  allowDelete={formik.values.aliquotId !== '' && formik.values.rgcBarcode === '' ? true : false}
                  index={0}
                  onDelete={onDelete}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm>
            <FormInput
              label="RGC ID"
              type="text"
              name={`rgcBarcode`}
              error={formik.errors.rgcBarcode}
              touched={formik.touched.rgcBarcode}
              onChange={(e: any) => {
                formik.handleChange(e);
                formik.setFieldTouched(`rgcBarcode`, true);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.rgcBarcode}
              margin="none"
              startAdornment={<RiBarcodeLine />}
              readOnly
              endAdornment={
                <EndAdornment
                  allowDelete={formik.values.rgcBarcode !== '' ? true : false}
                  isActive={formik.values.aliquotId !== '' && formik.values.rgcBarcode === ''}
                  index={1}
                  onDelete={() => {
                    formik.setFieldValue('rgcBarcode', '');
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              type="submit"
              variant="outlined"
              size="large"
              color="primary"
              fullWidth
              sx={{ height: '100%', fontSize: '20px' }}
              data-testid="link-button"
            >
              {buttonLabel} <RiArrowRightLine />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
}

export default LinkingForm;
