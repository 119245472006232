import React, { ReactNode } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useStyles } from './component.style';
import Box from '@mui/material/Box';

export interface SamplesTableProps {
  children: ReactNode;
}

function SamplesTable({ children }: SamplesTableProps) {
  const { classes } = useStyles();
  return (
    <Box mb={2}>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Primary
              </TableCell>
              <TableCell align="center" colSpan={3} className={classes.borderLeft}>
                Aliquot 1
              </TableCell>
              <TableCell align="center" colSpan={3} className={classes.borderLeft}>
                Aliquot 2
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Vol (ml)</TableCell>
              <TableCell align="center" className={classes.borderLeft}>
                ID
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Vol (ml)</TableCell>
              <TableCell align="center" className={classes.borderLeft}>
                ID
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Vol (ml)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SamplesTable;
