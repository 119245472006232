import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { Cryobox } from '../../core/api/lab.types';

export const hasErrorSelector = (state: RootState): boolean => (state.rgcLinking.errors ? true : false);

export const firstErrorSelector = (state: RootState): string | null =>
  state.rgcLinking.errors ? state.rgcLinking.errors[0].errorType : null;

export const isBusySelector = (state: RootState): boolean => state.rgcLinking.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Cryobox | null => state.rgcLinking.response;

export const isUpdatedSelector = (state: RootState): boolean | null => state.rgcLinking.isUpdated;
