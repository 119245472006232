import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormStepper } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../../../../../layout/Inner';
import Panel from '../../../../../../../../components/layout/Panel';
import FORMS from '../../../../../../../../core/constants/forms.constants';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../../../../core/constants/routes.constants';
import AlertSuccess from '../../../../../../../../components/content/AlertSuccess';

const CompleteScene = () => {
  const navigateTo = useNavigate();
  return (
    <InnerLayout>
      <Box mb={2}>
        <Typography variant="h4" component="h1" align="center" paragraph mb={5}>
          Sample Storage
        </Typography>
        <FormStepper steps={FORMS.STORAGE_STEPS} activeStep={FORMS.STORAGE_STEPS.length} />
      </Box>
      <Panel mb={6} mt={5} title="Samples &amp; Aliquots Submitted">
        <AlertSuccess
          title="Success"
          description="We have received your storage details. You can now exit
          the sample storage process."
        />
      </Panel>
      <ButtonRow
        showBack={false}
        forwardLabel="End storage"
        onForward={() => {
          navigateTo(ROUTES.portal);
        }}
        buttonSize="large"
        forwardColor="primary"
      />
    </InnerLayout>
  );
};

export default CompleteScene;
