import { createAsyncThunk } from '@reduxjs/toolkit';
import labApi from '../../core/api/lab.api';
import { GetSampleBatchResponse } from '../../core/api/lab.types';

/* Get Sample Batch */
export const getSampleBatchThunk = createAsyncThunk(
  'batch/getSampleBatch',
  async (id: string, { rejectWithValue }: any) => {
    const response: GetSampleBatchResponse = await labApi.getSampleBatch(id);
    if (response.data?.getSampleBatch) {
      return response.data?.getSampleBatch;
    }
    return rejectWithValue(response.errors);
  },
);
