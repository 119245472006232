import { Cryobox, GraphQlError } from '../../core/api/lab.types';
import { StoreStatus } from '../../core/types/common.types';

/* State */
export interface rgcLinkingState {
  errors: GraphQlError[] | null;
  status: StoreStatus;
  response: Cryobox | null;
  isUpdated: boolean | null;
}

export enum LinkingErrorTypes {
  ALIQUOT_NOT_FOUND = 'AliquotNotFoundException',
}
